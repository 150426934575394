import { Box } from '@chakra-ui/react'
import LessonAdminCard from 'Components/Cards/AdminCards/LessonAdminCard'
import { ILessonPlan } from 'Models/LessonPlan-type'

interface LessonPlanPanelProps {
   LessonsPlansList: ILessonPlan[]
   type? : string
}

const LessonPlanPanel: React.FunctionComponent<LessonPlanPanelProps> = (
   props
) => {
   return (
      <>
         <Box className="flex flex-wrap w-full gap-4 px-6 items-center justify-center">
            {props.LessonsPlansList.map((item: ILessonPlan) => (
               <LessonAdminCard data={item} type={props.type} />
            ))}
         </Box>
      </>
   )
}

export default LessonPlanPanel
