import { Box, Button, Text } from '@chakra-ui/react'
import { IVideos } from 'Models/Videos-type'
import { currentLang } from 'Utils/HelperMethods'

interface VideoDetailsProps {
   data: IVideos
}

const VideoDetails: React.FunctionComponent<VideoDetailsProps> = (props) => {
   return (
      <>
         <Box className="bg-light-blue pt-8 max-h-[23rem] lg:max-h-full lg:items-start lg:py-8  flex w-full lg:flex-col">
            <Box className="py-12 w-1/2 lg:w-full flex flex-col gap-y-5 items-start lg:items-start px-16 lg:px-8">
               {currentLang === 'en' ? (
                  <>
                     <Button cursor="default" variant="video">
                        {props.data.type}
                     </Button>
                     <Text className="text-2xl text-primary font-normal">
                        {props.data.title}
                     </Text>
                     <Text className="text-lg text-white font-light">
                        {props.data.leadText}
                     </Text>
                  </>
               ) : (
                  <>
                     <Button cursor="default" variant="video">
                        {props.data.arType}
                     </Button>
                     <Text className="text-2xl text-primary font-normal">
                        {props.data.arTitle}
                     </Text>
                     <Text className="text-lg text-white font-light">
                        {props.data.arLeadText}
                     </Text>
                  </>
               )}
            </Box>
         </Box>
         <Box className="w-full p-8 lg:p-1 lg:w-full flex justify-center min-h-[50vh]">
            <Box className="rounded-lg h-[35rem] w-2/3 lg:w-full lg:h-[30rem] flex justify-center shadow-lg border bg-white p-12">
               <iframe
                  width="100%"
                  height="100%"
                  src={props.data.VideoURL}
                  title={props.data.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
            </Box>
         </Box>
      </>
   )
}

export default VideoDetails
