import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enLocale from 'i18n/en/translation.json'
import arLocale from 'i18n/ar/translation.json'
import { currentLang } from 'Utils/HelperMethods'

export function i18nextInit() {
   if (currentLang === null) {
      localStorage.setItem('i18nextLng', 'ar')
   }
   i18n.use(initReactI18next).init({
      resources: {
         en: {
            translation: enLocale
         },
         ar: {
            translation: arLocale
         }
      },
      lng: currentLang,
      fallbackLng: 'ar',
      interpolation: {
         escapeValue: false
      }
   })
   if (currentLang === 'en') {
      document.body.dir = 'ltr'
   }
   if (currentLang === 'ar') {
      document.body.dir = 'rtl'
   }
   if (currentLang === null) {
      document.body.dir = 'rtl'
   }
}
