import { useToast } from '@chakra-ui/react'
import { useApiRequests } from 'API/apiRequests'
import { useMutation, useQueryClient } from 'react-query'
import { useRecoilState } from 'recoil'
import { CurrentAdminView } from 'Recoil/Atoms'

export const useAddCourse = () => {
   const { addCourse } = useApiRequests()
   const [, setCurrentView] = useRecoilState(CurrentAdminView)

   const toast = useToast()
   const queryClient = useQueryClient()

   const { isLoading, mutate } = useMutation(addCourse, {
      onError: () => {
         queryClient.invalidateQueries('addedCourse')
         toast({
            title: 'Something went Wrong',
            status: 'error',
            duration: 2000,
            position: 'top',
            isClosable: true
         })
      },
      onSuccess: () => {
         queryClient.invalidateQueries('lessonBySection')
         toast({
            title: 'Course Added Successfully',
            status: 'success',
            duration: 2000,
            position: 'top',
            isClosable: true
         })
         setTimeout(() => {
            setCurrentView('courses-list')
         }, 1500)
      }
   })

   return { mutate, isLoading }
}
