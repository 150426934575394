import { useApiRequests } from 'API/apiRequests'
import { useQuery } from 'react-query'

export const useGetLessonsPlans = () => {
   const { getLessonPlans } = useApiRequests()
   const { data, isLoading } = useQuery(['Lesson-Plans'], getLessonPlans)
   const LessonPlans = data?.data.data.filter((item: { draft: number }) => {
      return item.draft === 1
   })
   return { LessonPlans, isLoading }
}
