import { Box, Text } from '@chakra-ui/react'
import LoginForm from 'Components/Forms/LoginForm'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { isLogin } from 'Utils/HelperMethods'

interface LoginProps {}

const Login: React.FunctionComponent<LoginProps> = () => {
   const navigate = useNavigate()
   const { t } = useTranslation('translation')
   useEffect(() => {
      if (isLogin !== undefined) {
         navigate('../my-courses')
      }
   }, [])

   return (
      <>
         <Box className="flex flex-col justify-center lg:min-h-[90vh] items-center lg:justify-start lg:pt-8 w-full overflow-x-hidden">
            <Box className="w-11/12 py-20 lg:py-6">
               <Text className="text-primary font-semibold text-2xl py-12 lg:py-6 text-center">
                  {t('log_in_to_your')}
               </Text>
               <Box className="bg-white shadow-2xl flex">
                  <Box className="w-full h-full lg:hidden">
                     <img src="Images\woman-login.png" />
                  </Box>
                  <Box className="w-full flex flex-col justify-center border-r border-l items-center">
                     <LoginForm />
                     <Text className="py-2">
                        {t('Dont-have-an-account-?')}{' '}
                        <Link to="../register">
                           <span className="text-primary underline ">
                              {t('sign-up')}
                           </span>
                        </Link>
                     </Text>
                  </Box>
               </Box>
            </Box>
         </Box>
      </>
   )
}

export default Login
