import AddModal from 'Components/Modal/AddModal'
import { Box, Button, Input, InputGroup } from '@chakra-ui/react'
import { AddSectionSchema } from 'Configs/formsSchemas'
import React from 'react'
import { Form, Formik } from 'formik'
import { IReactQuery } from 'Models/ReactQuery-type'

interface AddSectionPanelProps {
   id: number | undefined
   title?: string
   arTitle?: string
   mutate: IReactQuery
   modalTitle: string
   button: string
}

const AddSectionPanel: React.FunctionComponent<AddSectionPanelProps> = (
   props
) => {
   return (
      <>
         <AddModal title={props.modalTitle}>
            <Formik
               initialValues={{
                  title: props.title,
                  arTitle: props.arTitle,
                  course_id: props.id
               }}
               onSubmit={(e) => {
                  props.mutate.mutate(e)
               }}
               validationSchema={AddSectionSchema}>
               {({ handleChange, handleSubmit, values }) => (
                  <Form className="w-3/5 lg:w-4/5" onSubmit={handleSubmit}>
                     <Box className="py-4">
                        <Input
                           type="text"
                           name="title"
                           className="font-medium py-4"
                           variant="filled"
                           placeholder="Title"
                           onChange={handleChange}
                           value={values.title}
                        />
                     </Box>
                     <Box className="py-4">
                        <InputGroup alignItems="center" size="md">
                           <Input
                              className="font-medium"
                              variant="filled"
                              placeholder="Arabic Title"
                              type="text"
                              name="arTitle"
                              onChange={handleChange}
                              value={values.arTitle}
                           />
                        </InputGroup>
                     </Box>
                     <Box className="flex justify-between pt-12">
                        <Button
                           justifyContent="center"
                           variant="primary"
                           isLoading={props.mutate.isLoading}
                           type="submit">
                           {props.button}
                        </Button>
                     </Box>
                  </Form>
               )}
            </Formik>
         </AddModal>
      </>
   )
}

export default AddSectionPanel
