import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper'
import SliderCard from 'Components/Cards/SliderCard'
import { ISliderCardData } from 'Models/SliderCard-type'

interface SliderProps {
   data: ISliderCardData[]
}

const Slider: React.FunctionComponent<SliderProps> = (props) => {
   return (
      <>
         <Swiper
            slideNextClass="slider-arrow"
            breakpoints={{
               640: {
                  slidesPerView: 2,
                  spaceBetween: 0
               },
               768: {
                  slidesPerView: 3,
                  spaceBetween: 8
               },
               1500: {
                  slidesPerView: 4,
                  spaceBetween: 8
               }
            }}
            navigation={true}
            modules={[Navigation]}>
            {props.data?.map((item: ISliderCardData) => {
               return (
                  <SwiperSlide
                     key={item.id}
                     className="border m-4 bg-white w-80 h-72">
                     <SliderCard data={item} />
                  </SwiperSlide>
               )
            })}
         </Swiper>
      </>
   )
}

export default Slider
