import {
   Box,
   chakra,
   Flex,
   SimpleGrid,
   Stat,
   StatLabel,
   StatNumber,
   useColorModeValue
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { BsPerson } from 'react-icons/bs'
import { BiCategoryAlt } from 'react-icons/bi'
import { GiBookshelf } from 'react-icons/gi'
import { MdOutlinePlayLesson, MdOndemandVideo } from 'react-icons/md'

interface StatsCardProps {
   title: string
   stat: string
   icon: ReactNode
}

function StatsCard(props: StatsCardProps) {
   const { title, stat, icon } = props
   return (
      <Stat
         px={{ base: 2, md: 4 }}
         py={'5'}
         shadow={'xl'}
         border={'1px solid'}
         borderColor="dark-blue"
         rounded={'lg'}>
         <Flex justifyContent={'space-between'}>
            <Box pl={{ base: 2, md: 4 }}>
               <StatLabel fontWeight={'bold'} color="primary" isTruncated>
                  {title}
               </StatLabel>
               <StatNumber
                  fontSize={'3xl'}
                  color="primary"
                  fontWeight={'semibold'}>
                  {stat}
               </StatNumber>
            </Box>
            <Box
               my={'auto'}
               color={useColorModeValue('gray.800', 'gray.200')}
               alignContent={'center'}>
               {icon}
            </Box>
         </Flex>
      </Stat>
   )
}
interface StatisticsProps {
   courses: string
   videos: string
   category: string
   lessons: string
   users: string
}

export default function Statistics(props: StatisticsProps) {
   return (
      <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
         <chakra.h1
            textAlign={'center'}
            fontSize={'4xl'}
            color="primary"
            py={10}
            fontWeight={'bold'}>
            Statistics
         </chakra.h1>
         <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
            <StatsCard
               title={'Users'}
               stat={props.users}
               icon={<BsPerson className="text-dark-blue" size={'4em'} />}
            />
            <StatsCard
               title={'Category'}
               stat={props.category}
               icon={<BiCategoryAlt className="text-dark-blue" size={'4em'} />}
            />
            <StatsCard
               title={'Courses'}
               stat={props.courses}
               icon={<GiBookshelf className="text-dark-blue" size={'4em'} />}
            />
            <StatsCard
               title={'Lesson Plans'}
               stat={props.lessons}
               icon={
                  <MdOutlinePlayLesson
                     className="text-dark-blue"
                     size={'3em'}
                  />
               }
            />
            <StatsCard
               title={'Videos'}
               stat={props.videos}
               icon={
                  <MdOndemandVideo className="text-dark-blue" size={'4em'} />
               }
            />
         </SimpleGrid>
      </Box>
   )
}
