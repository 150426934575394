import {
   Box,
   Button,
   Input,
   InputGroup,
   InputLeftElement,
   InputRightElement,
   Text
} from '@chakra-ui/react'
import { ErrorMessage, Form, Formik } from 'formik'
import { loginSchema } from 'Configs/formsSchemas'
import React from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { currentLang } from 'Utils/HelperMethods'
import { useLogin } from 'Hooks/queries/useLogin'
interface LoginFormProps {}

const LoginForm: React.FunctionComponent<LoginFormProps> = () => {
   const { t } = useTranslation('translation')
   const { mutate, isLoading } = useLogin()
   const [show, setShow] = React.useState(false)
   const handleClick = () => setShow(!show)

   return (
      <Formik
         initialValues={{
            email: '',
            password: ''
         }}
         onSubmit={(e) => {
            mutate(e)
         }}
         validationSchema={loginSchema}>
         {({ handleChange, handleSubmit, values }) => (
            <Form className="w-3/5 lg:w-4/5" onSubmit={handleSubmit}>
               <Box className="py-4">
                  <Input
                     type="email"
                     name="email"
                     className="font-medium py-4"
                     variant="filled"
                     placeholder={t('enter_email')}
                     onChange={handleChange}
                     value={values.email}
                  />
                  <ErrorMessage
                     render={(error) => (
                        <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                           {t(error)}
                        </Box>
                     )}
                     name="email"
                     component="div"
                  />
               </Box>
               <Box className="py-4">
                  <InputGroup alignItems="center" size="md">
                     <Input
                        className="font-medium"
                        variant="filled"
                        placeholder={t('enter_password')}
                        type={show ? 'text' : 'password'}
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                     />
                     {currentLang === 'ar' ? (
                        <InputLeftElement>
                           {show ? (
                              <AiFillEye onClick={handleClick} />
                           ) : (
                              <AiFillEyeInvisible onClick={handleClick} />
                           )}
                        </InputLeftElement>
                     ) : (
                        <InputRightElement height="100%" width="4.5rem">
                           {show ? (
                              <AiFillEye onClick={handleClick} />
                           ) : (
                              <AiFillEyeInvisible onClick={handleClick} />
                           )}
                        </InputRightElement>
                     )}
                  </InputGroup>
                  <ErrorMessage
                     render={(error) => (
                        <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                           {t(error)}
                        </Box>
                     )}
                     name="password"
                     component="div"
                  />
               </Box>
               <Link to="../resetpassword">
                  <Text
                     variant="p"
                     className="text-primary font-medium text-md underline">
                     {t('resetpassword')}
                  </Text>
               </Link>
               <Box className="flex justify-between pt-12">
                  <Button
                     justifyContent="center"
                     variant="primary"
                     isLoading={isLoading}
                     type="submit">
                     {t('sign_in')}
                  </Button>
               </Box>
            </Form>
         )}
      </Formik>
   )
}

export default LoginForm
