import AddModal from 'Components/Modal/AddModal'
import { Box, Button, Input, InputGroup, Text } from '@chakra-ui/react'
import { AddLessonSchema } from 'Configs/formsSchemas'
import React from 'react'
import { Form, Formik } from 'formik'
import { IReactQuery } from 'Models/ReactQuery-type'
import TinyEditorAdd from 'Components/Inputs/TinyEditorAdd'

interface AddLessonPanelProps {
   section_id: number
   title?: string
   text?: string
   arText?: string
   arTitle?: string
   mutate: IReactQuery
   modalTitle: string
}

const AddLessonPanel: React.FunctionComponent<AddLessonPanelProps> = (
   props
) => {
   return (
      <>
         <AddModal title={props.modalTitle}>
            <Formik
               initialValues={{
                  section_id: props.section_id,
                  title: props.title,
                  arTitle: props.arTitle,
                  text: props.text,
                  arText: props.arText
               }}
               onSubmit={(e) => {
                  props.mutate.mutate(e)
               }}
               validationSchema={AddLessonSchema}>
               {({ handleChange, handleSubmit, values, setFieldValue }) => (
                  <Form className="w-full" onSubmit={handleSubmit}>
                     <Box className="py-4">
                        <Input
                           type="text"
                           name="title"
                           className="font-medium py-4"
                           variant="filled"
                           placeholder="Title"
                           onChange={handleChange}
                           value={values.title}
                        />
                     </Box>
                     <Box className="py-4">
                        <InputGroup alignItems="center" size="md">
                           <Input
                              className="font-medium"
                              variant="filled"
                              placeholder="Arabic Title"
                              type="text"
                              name="arTitle"
                              onChange={handleChange}
                              value={values.arTitle}
                           />
                        </InputGroup>
                     </Box>
                     <Text className="text-2xl text-primary py-4 font-semibold">
                        Text
                     </Text>
                     <TinyEditorAdd
                        initValue={values.text}
                        setEditable={(value) => setFieldValue('text', value)}
                     />
                     <Text className="text-2xl text-primary py-4 font-semibold">
                        Arabic Text
                     </Text>
                     <TinyEditorAdd
                        initValue={values.arText}
                        setEditable={(value) => setFieldValue('arText', value)}
                     />
                     <Box className="flex justify-between pt-12">
                        <Button
                           justifyContent="center"
                           variant="primary"
                           isLoading={props.mutate.isLoading}
                           type="submit">
                           Add
                        </Button>
                     </Box>
                  </Form>
               )}
            </Formik>
         </AddModal>
      </>
   )
}

export default AddLessonPanel
