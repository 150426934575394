import { useToast } from '@chakra-ui/react'
import { useApiRequests } from 'API/apiRequests'
import { useMutation, useQueryClient } from 'react-query'

export const useUpdateLessonPlan = () => {
   const { updateLesson } = useApiRequests()
   const queryClient = useQueryClient()
   const toast = useToast()

   const { isLoading, mutate } = useMutation(updateLesson, {
      onError: () => {
         queryClient.invalidateQueries('updateLesson')
         toast({
            title: 'Something went Wrong',
            status: 'error',
            duration: 2000,
            position: 'top',
            isClosable: true
         })
      },
      onSuccess: () => {
         queryClient.invalidateQueries('updateLesson')
         toast({
            title: 'Lesson Plan Updated Successfully',
            status: 'success',
            duration: 2000,
            position: 'top',
            isClosable: true
         })
      }
   })

   return { mutate, isLoading }
}
