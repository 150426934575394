import { useApiRequests } from 'API/apiRequests'
import { useQuery } from 'react-query'

export const useGetFeatureCourses = () => {
   const { getFeatureCourses } = useApiRequests()
   const { data, isLoading } = useQuery(['FeatureCourses'], getFeatureCourses)
   const FeatureCourses = data?.data.data
   const removeDraft = FeatureCourses?.filter((item: { draft: number }) => {
      return item.draft === 1
   })
   return { removeDraft, isLoading }
}
