import {
   Popover,
   PopoverContent,
   PopoverHeader,
   PopoverBody,
   PopoverArrow,
   PopoverCloseButton,
   Button,
   ButtonGroup
} from '@chakra-ui/react'
import { useRef } from 'react'
import { PopoverTrigger as OrigPopoverTrigger } from '@chakra-ui/react'
import DeleteDialog from 'Components/AlertDialog/DeleteDialog'
import { useDeleteLesson } from 'Hooks/queries/Admin/Lessons/useDeleteLesson'
import AddLessonPanel from 'Components/Panels/AdminPanel/AddLessonPanel'
import { useUpateLesson } from 'Hooks/queries/Admin/Lessons/useUpateLesson'

interface ButtonsPopOverProps {
   id: number
   title?: string
   text?: string
   arText?: string
   arTitle?: string
}

const ButtonsPopOver: React.FunctionComponent<ButtonsPopOverProps> = (
   props
) => {
   const UpdateLesson = useUpateLesson()
   const DeleteLesson = useDeleteLesson()
   const initialFocusRef = useRef<any>()
   const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
      OrigPopoverTrigger

   return (
      <Popover
         initialFocusRef={initialFocusRef}
         placement="top"
         closeOnBlur={false}>
         <PopoverTrigger>
            <Button>Actions</Button>
         </PopoverTrigger>
         <PopoverContent
            boxSize="200"
            height="100"
            color="white"
            bg="blue.800"
            borderColor="blue.800">
            <PopoverHeader pt={4} fontWeight="bold" border="0">
               Actions
            </PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
               <ButtonGroup placeItems="center">
                  <DeleteDialog
                     title="Delete"
                     deleteCourse={DeleteLesson}
                     id={props.id}
                  />
                  <AddLessonPanel
                     arText={props.arText}
                     arTitle={props.arTitle}
                     text={props.text}
                     title={props.title}
                     modalTitle="Update"
                     mutate={UpdateLesson}
                     section_id={props.id}
                  />
               </ButtonGroup>
            </PopoverBody>
         </PopoverContent>
      </Popover>
   )
}

export default ButtonsPopOver
