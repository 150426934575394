import {
   Box,
   Button,
   FormLabel,
   Input,
   InputGroup,
   InputLeftElement,
   InputRightElement,
   Radio,
   RadioGroup,
   Select,
   Text
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { currentLang } from 'Utils/HelperMethods'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useState } from 'react'
import { EditProfileSchema } from 'Configs/formsSchemas'
import { useTranslation } from 'react-i18next'
import CountryList from 'Mock/country.json'
import Genders from 'Mock/genders.json'
import { IUserData } from 'Models/UserData-type'
import { useUpdateData } from 'Hooks/queries/useUpdateData'

interface EditProfileFormProps {
   data: IUserData
}

const EditProfileForm: React.FunctionComponent<EditProfileFormProps> = (
   props
) => {
   const { mutate, isLoading } = useUpdateData()
   const [show, setShow] = useState(false)
   const handleClick = () => setShow(!show)
   const { t } = useTranslation('translation')

   return (
      <>
         <Formik
            initialValues={{
               email: props.data?.email,
               name: props.data?.fullName,
               date: props.data?.birthday,
               gender: props.data?.gender,
               country: props.data?.country,
               password: '',
               password_confirmation: '',
               lang: props.data?.prifLang
            }}
            onSubmit={(e) => {
               mutate(e)
            }}
            validationSchema={EditProfileSchema}>
            {({ handleChange, handleSubmit, values }) => (
               <Form className="w-1/2 lg:w-full" onSubmit={handleSubmit}>
                  <Box className="py-4 flex items-center">
                     <Text className="w-1/3">{t('Email_Address')}</Text>
                     <Input
                        type="email"
                        name="email"
                        className="font-medium py-4"
                        variant="outline"
                        onChange={handleChange}
                        value={values.email}
                     />
                  </Box>
                  <Box className="py-4 flex items-center">
                     <Text className="w-1/3">{t('Name')}</Text>
                     <Input
                        type="text"
                        name="name"
                        className="font-medium py-4"
                        variant="outline"
                        onChange={handleChange}
                        value={values.name}
                     />
                  </Box>
                  <Box className="py-4 flex items-center">
                     <Text className="w-1/3">{t('Date_of_brith')}</Text>
                     <Input type="date" onChange={handleChange} name="date" />
                  </Box>
                  <Box className="py-4 flex items-center">
                     <Text className="w-1/3">{t('Gender')}</Text>
                     <Select onChange={handleChange} name="gender">
                        <option value=""></option>
                        {Genders.map((item) => (
                           <option key={item.name} value={item.name}>
                              {item.name}
                           </option>
                        ))}
                     </Select>
                  </Box>
                  <Box className="py-4 flex items-center">
                     <Text className="w-1/3">{t('Country')}</Text>
                     <Select onChange={handleChange} name="country">
                        <option value=""></option>
                        {CountryList.map((item) => (
                           <option key={item.name} value={item.name}>{item.name}</option>
                        ))}
                     </Select>
                  </Box>
                  <Box className="py-4 flex items-center">
                     <Text className="w-1/3">{t('Password')}</Text>
                     <InputGroup alignItems="center" size="md">
                        <Input
                           className="font-medium"
                           variant="outline"
                           type={show ? 'text' : 'password'}
                           name="password"
                           onChange={handleChange}
                           value={values.password}
                        />
                        {currentLang === 'ar' ? (
                           <InputLeftElement>
                              {show ? (
                                 <AiFillEye onClick={handleClick} />
                              ) : (
                                 <AiFillEyeInvisible onClick={handleClick} />
                              )}
                           </InputLeftElement>
                        ) : (
                           <InputRightElement height="100%" width="4.5rem">
                              {show ? (
                                 <AiFillEye onClick={handleClick} />
                              ) : (
                                 <AiFillEyeInvisible onClick={handleClick} />
                              )}
                           </InputRightElement>
                        )}
                     </InputGroup>
                  </Box>
                  <Box className="py-4 flex items-center">
                     <Text className="w-1/3">{t('Password_confirmation')}</Text>
                     <InputGroup alignItems="center" size="md">
                        <Input
                           className="font-medium"
                           variant="outline"
                           type={show ? 'text' : 'password'}
                           name="password_confirmation"
                           onChange={handleChange}
                           value={values.password_confirmation}
                        />
                        {currentLang === 'ar' ? (
                           <InputLeftElement>
                              {show ? (
                                 <AiFillEye onClick={handleClick} />
                              ) : (
                                 <AiFillEyeInvisible onClick={handleClick} />
                              )}
                           </InputLeftElement>
                        ) : (
                           <InputRightElement height="100%" width="4.5rem">
                              {show ? (
                                 <AiFillEye onClick={handleClick} />
                              ) : (
                                 <AiFillEyeInvisible onClick={handleClick} />
                              )}
                           </InputRightElement>
                        )}
                     </InputGroup>
                  </Box>
                  <Box className="py-4 flex items-center">
                     <FormLabel className="w-1/3" htmlFor="email-alerts" mb="0">
                        {t('priflang')}
                     </FormLabel>

                     <RadioGroup
                        onChange={handleChange}
                        name="lang"
                        display="flex"
                        gap="5"
                        defaultValue={values.lang}>
                        <Radio colorScheme="green" value="en">
                           En
                        </Radio>
                        <Radio colorScheme="green" value="ar">
                           العربية
                        </Radio>
                     </RadioGroup>
                  </Box>
                  <Box className="flex justify-between pt-12">
                     <Button
                        justifyContent="center"
                        variant="primary"
                        isLoading={isLoading}
                        type="submit">
                        {t('Update_Profile')}
                     </Button>
                  </Box>
               </Form>
            )}
         </Formik>
      </>
   )
}

export default EditProfileForm
