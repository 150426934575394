import { Box } from '@chakra-ui/react'
import CourseAdminCard from 'Components/Cards/AdminCards/CourseAdminCard'
import { ISliderCardData } from 'Models/SliderCard-type'

interface CoursesPanelProps {
   CoursesList: ISliderCardData[]
}

const CoursesPanel: React.FunctionComponent<CoursesPanelProps> = (props) => {
   return (
      <>
         <Box className="flex flex-wrap w-full gap-4 px-6 items-center justify-center">
            {props.CoursesList.map((item: ISliderCardData) => (
               <CourseAdminCard data={item} />
            ))}
         </Box>
      </>
   )
}

export default CoursesPanel
