import {
   Box,
   Button,
   Center,
   Heading,
   Image,
   Stack,
   Text,
   useColorModeValue
} from '@chakra-ui/react'
import DeleteDialog from 'Components/AlertDialog/DeleteDialog'
import { useDeleteCourse } from 'Hooks/queries/Admin/Courses/useDeleteCourse'
import { ISliderCardData } from 'Models/SliderCard-type'
import { Link } from 'react-router-dom'

interface CourseAdminCardProps {
   data: ISliderCardData
}

const CourseAdminCard: React.FunctionComponent<CourseAdminCardProps> = (
   props
) => {
   const IMAGE = props.data.imgThumbnail
   const deleteCourse = useDeleteCourse()

   return (
      <Center key={props.data.id} py={12}>
         <Box
            role={'group'}
            p={6}
            maxW={'330px'}
            w={'full'}
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow={'2xl'}
            rounded={'lg'}
            pos={'relative'}
            zIndex={1}>
            <Box
               rounded={'lg'}
               mt={-12}
               pos={'relative'}
               height={'230px'}
               _after={{
                  transition: 'all .3s ease',
                  content: '""',
                  w: 'full',
                  h: 'full',
                  pos: 'absolute',
                  top: 5,
                  left: 0,
                  backgroundImage: `url(${IMAGE})`,
                  filter: 'blur(15px)',
                  zIndex: -1
               }}
               _groupHover={{
                  _after: {
                     filter: 'blur(20px)'
                  }
               }}>
               <Image
                  rounded={'lg'}
                  height={230}
                  width={282}
                  objectFit={'cover'}
                  src={IMAGE}
               />
            </Box>
            <Stack pt={10} align={'center'}>
               <Text
                  color={'gray.500'}
                  fontSize={'sm'}
                  textTransform={'uppercase'}>
                  {props.data.arTitle}
               </Text>
               <Heading fontSize={'2xl'} fontFamily={'body'} fontWeight={500}>
                  {props.data.title}
               </Heading>
               <Stack direction={'row'} align={'center'}>
                  <Text fontWeight={800} fontSize={'xl'}>
                     ID : {props.data.id}
                  </Text>
               </Stack>
               <Stack
                  width={'100%'}
                  mt={'2rem'}
                  direction={'row'}
                  padding={2}
                  justifyContent={'space-between'}
                  alignItems={'center'}>
                  <DeleteDialog
                     id={props.data.id}
                     deleteCourse={deleteCourse}
                     title="Delete Course"
                  />
                  <Button variant="adminCard" bg="primary" color="white">
                     <Link to={`/admin/${props.data.link}`}>Edit</Link>
                  </Button>
                  <Button variant="adminCard" bg="blue.500" color="white">
                     <Link to={`../${props.data.link}`}>Show</Link>
                  </Button>
               </Stack>
            </Stack>
         </Box>
      </Center>
   )
}

export default CourseAdminCard
