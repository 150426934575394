import { useApiRequests } from 'API/apiRequests'
import { useQuery } from 'react-query'

export const useGetAllCoursesAdmin = () => {
   const { getCourses } = useApiRequests()
   const { data, isLoading, ...attributes } = useQuery(['courses'], getCourses)
   const Courses = data?.data.data

   return { Courses, isLoading, attributes }
}
