import { useApiRequests } from 'API/apiRequests'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { FilterdData, isEmpty } from 'Recoil/Atoms'

export const useSearchByID = () => {
   const toast = useToast()
   const [, setData] = useRecoilState(FilterdData)
   const [, setEmpty] = useRecoilState(isEmpty)
   const { t } = useTranslation('translation')
   const { getSearch } = useApiRequests()
   const queryClient = useQueryClient()
   const searchData: any[] = []

   const { isLoading, mutate } = useMutation(getSearch, {
      onSuccess: (data) => {
         queryClient.invalidateQueries('search')
         data.data.data.map((item: any[]) => {
            item.map((el: any) => {
               searchData.push(el)
            })
         })
         if (searchData.length === 0) {
            setEmpty(true)
            setData(searchData)
         } else {
            setData(searchData)
         }
      }
   })
   return { mutate, isLoading }
}
