import {
   Accordion,
   AccordionButton,
   AccordionIcon,
   AccordionItem,
   AccordionPanel,
   Box,
   Text
} from '@chakra-ui/react'
import DeleteDialog from 'Components/AlertDialog/DeleteDialog'
import AddLessonPanel from 'Components/Panels/AdminPanel/AddLessonPanel'
import AddSectionPanel from 'Components/Panels/AdminPanel/AddSectionPanel'
import LessonTable from 'Components/Table/LessonTable'
import { useAddLesson } from 'Hooks/queries/Admin/Lessons/useAddLesson'
import TextSketeton from 'Components/Skeleton/TextSketeton'
import { useGetLessons } from 'Hooks/queries/Admin/Lessons/useGetLessons'
import { useDeleteSection } from 'Hooks/queries/Admin/Sections/useDeleteSection'
import { useUpdateSections } from 'Hooks/queries/Admin/Sections/useUpdateSections'
import { ILessonSection } from 'Models/Lesson-type'
import { ISection } from 'Models/Section-type'

interface SectionsAccordionProps {
   sections: ISection
}

const SectionsAccordion: React.FunctionComponent<SectionsAccordionProps> = (
   props
) => {
   const { mutate, isLoading, lessonData } = useGetLessons()
   const Sections = useUpdateSections()
   const DeleteSections = useDeleteSection()
   const AddLesson = useAddLesson()
   return (
      <Accordion
         onChange={() => mutate(props.sections?.id)}
         key={props.sections?.id}
         allowToggle>
         <AccordionItem>
            <AccordionButton _expanded={{ bg: 'primary', color: 'white' }}>
               <Box flex="1" textAlign="left">
                  <Box className="flex justify-around w-full">
                     <Box className="flex gap-5">
                        <Text className="text-lg font-semibold">
                           {props?.sections?.title}
                        </Text>
                        <Text className="text-lg font-semibold">
                           {props?.sections?.arTitle}
                        </Text>
                     </Box>
                     <Box className="flex gap-4">
                        <AddSectionPanel
                           button="Update"
                           modalTitle="Edit"
                           id={props.sections?.id}
                           arTitle={props.sections?.arTitle}
                           title={props.sections?.title}
                           mutate={Sections}
                        />
                        <DeleteDialog
                           deleteCourse={DeleteSections}
                           id={props.sections.id}
                           title={props.sections?.title}
                        />
                        <AddLessonPanel
                           modalTitle="Add Lesson"
                           mutate={AddLesson}
                           section_id={props.sections.id}
                        />
                     </Box>
                  </Box>
               </Box>
               <AccordionIcon />
            </AccordionButton>

            {lessonData?.map((item: ILessonSection) => (
               <AccordionPanel pb={4}>
                  {isLoading ? (
                     <>
                        <TextSketeton times={2} />
                     </>
                  ) : (
                     <LessonTable data={item} />
                  )}
               </AccordionPanel>
            ))}
         </AccordionItem>
      </Accordion>
   )
}

export default SectionsAccordion
