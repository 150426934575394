import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

const axiosClient = axios.create()

export const useAxiosInstance = () => {
   const toast = useToast()
   const { t } = useTranslation('translation')

   const { REACT_APP_API_BASE_URL } = process.env

   axiosClient.defaults.baseURL = REACT_APP_API_BASE_URL

   axiosClient.defaults.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
   }

   axiosClient.interceptors.response.use(
      (response) =>
         // Dispatch any action on success
         response,
      (error) => {
         if (error.response.status === 401) {
            if (!toast.isActive('er')) {
               toast({
                  id: 'er',
                  title: t('invalid login'),
                  position: 'top',
                  description: t('Please check your login credentials'),
                  status: 'error',
                  duration: 3000,
                  isClosable: true
               })
            }
         }
         return Promise.reject(error)
      }
   )

   axiosClient.interceptors.request.use((config) => {
      const token = Cookies.get('token')
      config.headers.Authorization = token ? `Bearer ${token}` : ''
      return config
   })

   function setAuthToken(tokenP) {
      axiosClient.interceptors.request.use((config) => {
         config.headers.Authorization = tokenP ? `Bearer ${tokenP}` : ''
         return config
      })
   }

   return { setAuthToken, axiosClient }
}
