import { useAxiosInstance } from './axiosInstance'

export const useApiRequests = () => {
   const { axiosClient } = useAxiosInstance()

   return {
      getCourses: async () => axiosClient.get('/course/get-all'),
      getCategories: async () => axiosClient.get('/category/get-all'),
      getFeatureCourses: async () => axiosClient.get('/featureCourse/get-all'),
      getResourseType: async () => axiosClient.get('/resourceType/get-all'),
      getVideos: async () => axiosClient.get('/video/get-all'),
      getLessonPlans: async () => axiosClient.get('/lessonPlan/get-all'),
      getSearch: async (title) =>
         axiosClient.get(`/resource/search/get/title/${title}`),
      getFilterdData: async (id) =>
         axiosClient.get(`/category/getResourcesType/${id}`),
      getSelectResourseType: async (id) =>
         axiosClient.get(`/resource/search/get/type/${id}
         `),
      getSelectedBoth: async (data) =>
         axiosClient.get(
            `/resource/filtter/get/category/${data.Category}/type/${data.Type}`
         ),
      doLogin: async (data) => axiosClient.post('/login', data),
      doRegister: async (data) => axiosClient.post('/register', data),
      getEnrolled: async () => axiosClient.get('/user/course/get-all'),
      addEnroll: async (course) =>
         axiosClient.post('/user/course/add', { course_id: course }),
      getCourseLessons: async (id) =>
         axiosClient.get(`/course/courseSectionLesson/${id}`),
      getUserData: async () => axiosClient.get(`/user/get`),
      postUserData: async (data) =>
         axiosClient.post(`/user/UpdateProfile`, data),
      requestNewPassword: async (email) =>
         axiosClient.post(`/forget-password`, { email: email }),

      //Admin Requests
      getCoursesSections: async (id) =>
         axiosClient.get(`/sections/title/get/${id}`),
      getLessonBySection: async (id) =>
         axiosClient.get(`/lessons/section/get/${id}`),
      addCourse: async (data) =>
         axiosClient.post(`/course/add`, data, {
            headers: {
               'Content-Type': 'multipart/form-data'
            }
         }),
      addLessonPlan: async (data) =>
         axiosClient.post(`/lessonPlan/add`, data, {
            headers: {
               'Content-Type': 'multipart/form-data'
            }
         }),
      addVideo: async (data) =>
         axiosClient.post(`/video/add`, data, {
            headers: {
               'Content-Type': 'multipart/form-data'
            }
         }),
      deleteCourse: async (id) => axiosClient.post(`/course/delete/${id}`),
      deleteVideo: async (id) => axiosClient.post(`/video/delete/${id}`),
      deleteLessonPlan: async (id) =>
         axiosClient.post(`/lessonPlan/delete/${id}`),
      updateCourse: async (data) =>
         axiosClient.post(`/course/update/${data.id}`, data.data),
      updateLesson: async (data) =>
         axiosClient.post(`/lessonPlan/update/${data.id}`, data.data),
      updateVideo: async (data) =>
         axiosClient.post(`/video/update/${data.id}`, data.data),
      getAllUsers: async () => axiosClient.get(`/user/get-all`),
      addSection: async (data) => axiosClient.post(`/section/add`, data),
      deleteSection: async (id) => axiosClient.post(`/section/delete/${id}`),
      updateSection: async (data) =>
         axiosClient.post(`/section/update/${data.course_id}`, {
            title: data.title,
            arTitle: data.arTitle
         }),
      addLesson: async (data) => axiosClient.post(`/lesson/add`, data),
      deleteLesson: async (id) => axiosClient.post(`/lesson/delete/${id}`),
      updateLessonSection: async (data) =>
         axiosClient.post(`/lesson/update/${data.section_id}`, {
            title: data.title,
            arTitle: data.arTitle,
            text: data.text,
            arText: data.arText
         })
   }
}
