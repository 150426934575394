import { useToast } from '@chakra-ui/react'
import { useApiRequests } from 'API/apiRequests'
import { useMutation, useQueryClient } from 'react-query'
import { useGetLessons } from './useGetLessons'

export const useDeleteLesson = () => {
   const { deleteLesson } = useApiRequests()
   const queryClient = useQueryClient()
   const Lessons = useGetLessons()
   const toast = useToast()

   const { isLoading, mutate } = useMutation(deleteLesson, {
      onError: () => {
         queryClient.invalidateQueries('DeleteLesson')
         toast({
            title: 'Something went Wrong',
            status: 'error',
            duration: 2000,
            position: 'top',
            isClosable: true
         })
      },
      onSuccess: (data) => {
         queryClient.invalidateQueries('DeleteLesson')
         toast({
            title: 'Lesson Deleted Successfully',
            status: 'success',
            duration: 2000,
            position: 'top',
            isClosable: true
         })
         Lessons.attributes.mutateAsync(data.data.data.section_id)
      }
   })

   return { mutate, isLoading }
}
