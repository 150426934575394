import Cookies from 'js-cookie'

const useLogout = () => {
   const doLogout = () => {
      Cookies.remove('token')
      Cookies.remove('role')
      window.location.replace('../')
   }

   return { doLogout }
}

export default useLogout
