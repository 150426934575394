import { useApiRequests } from 'API/apiRequests'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'


export const useRequestNewPassword = () => {
   const toast = useToast()
   const { t } = useTranslation('translation')
   const { requestNewPassword } = useApiRequests()
   const queryClient = useQueryClient()

   const { isLoading, mutate } = useMutation(requestNewPassword, {
      onSuccess: () => {
         queryClient.invalidateQueries('changePassword')
         toast({
            title: t('send_successfully'),
            description: t('check_inbox'),
            status: 'success',
            duration: 1000,
            position: 'top',
            isClosable: true
         })

      }
   })
   return { mutate, isLoading }
}
