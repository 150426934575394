import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import useLogout from 'Hooks/useLogout'
import { useTranslation } from 'react-i18next'
import { BsPerson } from 'react-icons/bs'
import { Link } from 'react-router-dom'

interface ProfileMenuProps {}

const ProfileMenu: React.FunctionComponent<ProfileMenuProps> = () => {
   const { t } = useTranslation('translation')
   const { doLogout } = useLogout()

   return (
      <>
         <Menu>
            <MenuButton paddingX="3">
               <BsPerson color="dark-blue" className="text-5xl lg:text-3xl" />
            </MenuButton>
            <MenuList zIndex="popover" justifyItems="center">
               <Link to="../editprofile">
               <MenuItem color="dark-blue">{t('Edit_Profile')}</MenuItem>
               </Link>
               <Link to="../resetpassword">
               <MenuItem color="dark-blue">{t('reset_password')}</MenuItem>
               </Link>
               <MenuItem
                  onClick={doLogout}
                  color="dark-blue">
                  {t('logout')}
               </MenuItem>
            </MenuList>
         </Menu>
      </>
   )
}

export default ProfileMenu
