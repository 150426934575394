import { useMutation, useQueryClient } from 'react-query'
import { useApiRequests } from 'API/apiRequests'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const useUpdateData = () => {
   const { postUserData } = useApiRequests()
   const toast = useToast()
   const queryClient = useQueryClient()
   const { t } = useTranslation('translation')

   const { isLoading, mutate } = useMutation(postUserData, {
      onSuccess: () => {
         queryClient.invalidateQueries('updateProfile')
         toast({
            title: t('Profile_Updated_successfully'),
            status: 'success',
            duration: 1000,
            position: 'top',
            isClosable: true
         })
      }
   })
   return { mutate, isLoading }
}
