import { useApiRequests } from 'API/apiRequests'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { isLogin } from 'Recoil/Atoms'

export const useRegister = () => {
   const toast = useToast()
   const { t } = useTranslation('translation')
   const { doRegister } = useApiRequests()
   const queryClient = useQueryClient()
   const navigate = useNavigate()
   const [, setLogin] = useRecoilState(isLogin)

   const { isLoading, mutate } = useMutation(doRegister, {
      onSuccess: (data) => {
         queryClient.invalidateQueries('register')
         Cookies.set('token', data.data[0].success.token)
         setLogin(data.data[0].success.token)
         toast({
            title: t('register-successfully'),
            status: 'success',
            duration: 1000,
            position: 'top',
            isClosable: true
         })
         setTimeout(() => {
            navigate('../')
         }, 1000)
      },

      onError: (err: any) => {
         if (err.response.data.line) {
            toast({
               title: t('already_exist'),
               status: 'error',
               duration: 1000,
               position: 'top',
               isClosable: true
            }) 
         }
      }
   })
   return { mutate, isLoading }
}
