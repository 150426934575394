import { ILessonPlan } from 'Models/LessonPlan-type'
import { useRecoilState } from 'recoil'
import { CurrentEditLessonPlan } from 'Recoil/Atoms'

const useSetLessonPlansData = () => {
   const [, setCurrentEditable] = useRecoilState(CurrentEditLessonPlan)

   const setDefualtData = (data: ILessonPlan) => {
      setCurrentEditable({
         Title: data.title,
         'Arabic Title': data.arTitle,
         Thumbnail: data.imgThumbnail,
         LeadText: data.leadText,
         'Arabic LeadText': data.arLeadText,
         Description: data.description,
         'Arabic Description': data.arDescription,
         Duration: data.duration,
         Age: data.age,
         Status: data.draft,
         'File URL': data.LP_URL,
         'Arabic File URL': data.arLP_URL,
         Category: data.category_id,
         Level: data.level_id
      })
   }

   return { setDefualtData }
}

export default useSetLessonPlansData
