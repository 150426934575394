import { useToast } from '@chakra-ui/react'
import { useApiRequests } from 'API/apiRequests'
import { useMutation, useQueryClient } from 'react-query'
import { useGetLessonPlansAdmin } from './useGetLessonPlansAdmin'

export const useDeleteLessonPlan = () => {
   const { deleteLessonPlan } = useApiRequests()
   const queryClient = useQueryClient()
   const toast = useToast()
   const LessonPlans = useGetLessonPlansAdmin()

   const { isLoading, mutate } = useMutation(deleteLessonPlan, {
      onError: () => {
         queryClient.invalidateQueries('deletedCourse')
         toast({
            title: 'Something went Wrong',
            status: 'error',
            duration: 2000,
            position: 'top',
            isClosable: true
         })
      },
      onSuccess: () => {
         queryClient.invalidateQueries('deleteCourse')
         toast({
            title: 'Lesson Plan Deleted Successfully',
            status: 'success',
            duration: 2000,
            position: 'top',
            isClosable: true
         })
         LessonPlans.attributes.refetch()
      }
   })

   return { mutate, isLoading }
}
