import LoginNavbar from 'Layout/Navbar/LoginNavbar'
import Navbar from 'Layout/Navbar/Navbar'
import Footer from 'Layout/Footer/Footer'
import { useRecoilState } from 'recoil'
import { isLogin } from 'Recoil/Atoms'
import i18n from 'i18next'
import AdminRoutes from 'Routes/AdminRoutes'
import UserRoutes from 'Routes/UserRoutes'
import { isAdmin } from 'Utils/HelperMethods'
import { useEffect } from 'react'

function App() {
   const usedLang = localStorage.getItem('i18nextLng')

   useEffect(() => {
      if (usedLang === null) {
         localStorage.setItem('i18nextLng', 'ar')
         i18n.changeLanguage('ar')
      }
   }, [])
   const [islogin] = useRecoilState(isLogin)
   return (
      <>
         {islogin === undefined ? <Navbar /> : <LoginNavbar />}
         {isAdmin !== undefined ? <AdminRoutes /> : <UserRoutes />}
         <Footer />
      </>
   )
}

export default App
