import {
   Accordion,
   AccordionButton,
   AccordionIcon,
   AccordionItem,
   AccordionPanel,
   Box,
   Button
} from '@chakra-ui/react'
import { IAdminButtons } from 'Models/AdminButtons-type'
import { useRecoilState } from 'recoil'
import { CurrentAdminView } from 'Recoil/Atoms'

interface AdminListProps {
   title: string
   buttons: IAdminButtons[]
}

const AdminList: React.FunctionComponent<AdminListProps> = (props) => {
   const [, setCurrentView] = useRecoilState(CurrentAdminView)

   return (
      <>
         <Accordion key={props.title} allowToggle>
            <AccordionItem>
               <AccordionButton
                  paddingY="5"
                  _expanded={{ bg: 'primary', color: 'white' }}>
                  <Box flex="1" textAlign="center">
                     {props.title}
                  </Box>
                  <AccordionIcon />
               </AccordionButton>

               <AccordionPanel>
                  <Box className="flex flex-col gap-6">
                     {props.buttons.map((item) => (
                        <Button
                           key={item.title}
                           onClick={() => {
                              setCurrentView(item.link)
                           }}>
                           {item.title}
                        </Button>
                     ))}
                  </Box>
               </AccordionPanel>
            </AccordionItem>
         </Accordion>
      </>
   )
}

export default AdminList
