import { useApiRequests } from 'API/apiRequests'
import Cookies from 'js-cookie'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { isLogin } from 'Recoil/Atoms'

export const useLogin = () => {
   const toast = useToast()
   const { t } = useTranslation('translation')
   const { doLogin } = useApiRequests()
   const queryClient = useQueryClient()
   const [, setLogin] = useRecoilState(isLogin)

   const { isLoading, mutate } = useMutation(doLogin, {
      onSuccess: (data) => {
         if (
            data.data[0].message === 'You have been successfully Admin login'
         ) {
            Cookies.set('token', data.data[0].success)
            Cookies.set('role', data.data[0].success)
            setLogin(data.data[0].success)
            queryClient.invalidateQueries('login')
            toast({
               title: t('login-successfully'),
               status: 'success',
               duration: 1000,
               position: 'top',
               isClosable: true
            })
            setTimeout(() => {
               window.location.replace('../admin')
            }, 1000)
         } else {
            queryClient.invalidateQueries('login')
            Cookies.set('token', data.data[0].success)
            setLogin(data.data[0].success)
            toast({
               title: t('login-successfully'),
               status: 'success',
               duration: 1000,
               position: 'top',
               isClosable: true
            })
            setTimeout(() => {
               window.location.replace('../')
            }, 1000)
         }
      }
   })
   return { mutate, isLoading }
}
