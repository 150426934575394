import { useToast } from '@chakra-ui/react'
import { useApiRequests } from 'API/apiRequests'
import { useMutation, useQueryClient } from 'react-query'
import { useGetSections } from './useGetSections'

export const useUpdateSections = () => {
   const { updateSection } = useApiRequests()
   const queryClient = useQueryClient()
   const toast = useToast()
   const Sections = useGetSections()

   const { isLoading, mutate } = useMutation(updateSection, {
      onError: () => {
         queryClient.invalidateQueries('updateSections')
         toast({
            title: 'Something went Wrong',
            status: 'error',
            duration: 2000,
            position: 'top',
            isClosable: true
         })
      },
      onSuccess: (data) => {
         queryClient.invalidateQueries('updateCourse')
         toast({
            title: 'Section Updated Successfully',
            status: 'success',
            duration: 2000,
            position: 'top',
            isClosable: true
         })
         Sections.attributes.mutateAsync(data.data.data.course_id)
      }
   })

   return { mutate, isLoading }
}
