import {
   Modal,
   ModalOverlay,
   ModalContent,
   ModalFooter,
   ModalBody,
   useDisclosure,
   Button
} from '@chakra-ui/react'
import { ReactNode } from 'react'

interface LessonModalProps {
   children: ReactNode
   title: string
}

const LessonModal: React.FunctionComponent<LessonModalProps> = ({
   children,
   title
}) => {
   const { isOpen, onOpen, onClose } = useDisclosure()

   return (
      <>
         <Button onClick={onOpen}>{title}</Button>
         <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
               <ModalBody>{children}</ModalBody>
               <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                     Close
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   )
}

export default LessonModal
