import { Box, Text } from '@chakra-ui/react'

interface FooterProps {}

const Footer: React.FunctionComponent<FooterProps> = () => {
   return (
      <>
         <Box className="w-full bg-footer">
            <Text
               style={{ fontFamily: 'Montserrat' }}
               className="text-dark-blue text-center py-3 text-sm font-semibold">
               All Right Reserved - 2022 Thaki
            </Text>
         </Box>
      </>
   )
}

export default Footer
