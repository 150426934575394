import { useApiRequests } from 'API/apiRequests'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useGetEnrollCourses } from './useGetEnrollCourses'

export const useAddEnroll = () => {
   const { addEnroll } = useApiRequests()
   const { t } = useTranslation('translation')
   const queryClient = useQueryClient()
   const toast = useToast()
   const navigate = useNavigate()
   const courses = useGetEnrollCourses()

   const { isLoading, mutate } = useMutation(addEnroll, {
      onSuccess: () => {
         queryClient.invalidateQueries('addEnroll')
         toast({
            title: t('added_successfully'),
            status: 'success',
            duration: 1000,
            position: 'top',
            isClosable: true
         })
         courses.mutateAsync()
         navigate('../my-courses')
      }
   })

   return { mutate, isLoading }
}
