import * as yup from 'yup'

export const loginSchema = yup.object({
   email: yup
      .string()
      .email('Invalid email address')
      .required('Email Required'),
   password: yup.string().required('Password Required')
})

export const registerSchema = yup.object({
   email: yup
      .string()
      .email('Invalid email address')
      .required('Email Required'),
   password: yup
      .string()
      .min(6, 'should be at least 6 character(s)')
      .required(`Password Required`),
   password_confirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Password Confirmation Required')
})

export const forgetPassowrd = yup.object({
   email: yup.string().email('Invalid email address').required('Email Required')
})

export const resetPasswordSchema = yup.object({
   password: yup
      .string()
      .min(6, 'should be at least 6 character(s)')
      .required('Password Required'),
   password_confirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Password Confirmation Required')
})

export const EditProfileSchema = yup.object({
   email: yup
      .string()
      .email('Invalid email address')
      .required('Email Required'),
   name: yup.string(),
   date: yup.date(),
   gender: yup.string(),
   country: yup.string(),
   password: yup.string().min(6, 'should be at least 6 character(s)'),
   password_confirmation: yup
      .string()
      .min(6, 'should be at least 6 character(s)'),
   language: yup.string()
})

export const AddCourseSchema = yup.object({
   title: yup.string(),
   arTitle: yup.string(),
   leadText: yup.string(),
   arLeadText: yup.string(),
   imgThumbnail: yup.string(),
   description: yup.string(),
   arDescription: yup.string(),
   hours: yup.string(),
   downloadable: yup.string(),
   draft: yup.string(),
   category_id: yup.string(),
   level_id: yup.string()
})

export const AddLessonPlanSchema = yup.object({
   title: yup.string(),
   arTitle: yup.string(),
   leadText: yup.string(),
   arLeadText: yup.string(),
   imgThumbnail: yup.string(),
   description: yup.string(),
   arDescription: yup.string(),
   LP_URL: yup.string(),
   arLP_URL: yup.string(),
   duration: yup.string(),
   age: yup.string(),
   draft: yup.string(),
   category_id: yup.string(),
   level_id: yup.string()
})

export const AddVideoSchema = yup.object({
   title: yup.string(),
   arTitle: yup.string(),
   leadText: yup.string(),
   arLeadText: yup.string(),
   imgThumbnail: yup.string(),
   VideoURL: yup.string(),
   draft: yup.string(),
   category_id: yup.string(),
   level_id: yup.string()
})

export const AddSectionSchema = yup.object({
   title: yup.string(),
   arTitle: yup.string(),
   course_id: yup.number()
})

export const AddLessonSchema = yup.object({
   title: yup.string(),
   arTitle: yup.string(),
   text: yup.string(),
   arText: yup.string(),
   section_id: yup.string()
})
