import { SkeletonText } from '@chakra-ui/react'

interface TextSketetonProps {
   times: number
}

const TextSketeton: React.FunctionComponent<TextSketetonProps> = (props) => {
   return (
      <>
         <SkeletonText noOfLines={props.times} spacing="4" />
      </>
   )
}

export default TextSketeton
