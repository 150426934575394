import {
   Box,
   Button,
   Container,
   Flex,
   Heading,
   Select,
   List,
   ListItem,
   SimpleGrid,
   Stack,
   StackDivider,
   Text,
   useColorModeValue,
   VStack,
   Input
} from '@chakra-ui/react'
import EditableCard from 'Components/Cards/EditableCard'
import InputEditable from 'Components/Inputs/InputEditable'
import { useUpdateLessonPlan } from 'Hooks/queries/Admin/LessonPlans/useUpdateLessonPlan'
import useFillFormData from 'Hooks/useFillFormData'
import useSetLessonPlansData from 'Hooks/useSetLessonPlansData'
import useUpdateEditeCourse from 'Hooks/useUpdateEditeCourse'
import { ILessonPlan } from 'Models/LessonPlan-type'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { CurrentEditLessonPlan, CurrentUpdatedImage } from 'Recoil/Atoms'

interface AdminLessonPlansDatailsProps {
   data: ILessonPlan
}

const AdminLessonPlansDatails: React.FunctionComponent<
   AdminLessonPlansDatailsProps
> = (props) => {
   const [currentEditable] = useRecoilState(CurrentEditLessonPlan)
   const { setDefualtData } = useSetLessonPlansData()
   const [, setCurrentImage] = useRecoilState(CurrentUpdatedImage)
   const { doUpdateLesson } = useUpdateEditeCourse()
   const { doFillLessonPlan } = useFillFormData()
   const updateLessonPlan = useUpdateLessonPlan()

   useEffect(() => {
      setDefualtData(props.data)
   }, [])

   const hundleSave = () => {
      const formData = doFillLessonPlan(currentEditable)
      const mutateData = {
         data: formData,
         id: props.data.id
      }
      updateLessonPlan.mutate(mutateData)
   }

   return (
      <>
         <Container maxW={'8xl'}>
            <SimpleGrid
               columns={{ base: 1, lg: 2 }}
               spacing={{ base: 8, md: 10 }}
               py={{ base: 18, md: 24 }}>
               <Flex gap="20" direction="column">
                  <Input
                     w="100%"
                     h="100%"
                     onChange={(e) => {
                        setCurrentImage(e.currentTarget.files?.[0])
                     }}
                     backgroundRepeat="no-repeat"
                     backgroundImage={`url("${props.data.imgThumbnail}")`}
                     type="file"></Input>
                  <Box>
                     <Text className="text-3xl font-semibold pb-8">
                        Sections
                     </Text>
                     <Box>
                        <Text
                           fontSize={{ base: '16px', lg: '18px' }}
                           color="primary"
                           fontWeight={'500'}
                           textTransform={'uppercase'}
                           mb={'4'}>
                           Details
                        </Text>

                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                           <List spacing={2}>
                              <ListItem>Downloadable</ListItem>
                              <ListItem>Age</ListItem>
                              <ListItem>Duration</ListItem>
                           </List>
                           <List spacing={2}>
                              <ListItem>
                                 <InputEditable
                                    doUpdate={doUpdateLesson}
                                    title="Age"
                                    text={props.data?.age.toString()}
                                 />
                              </ListItem>
                              <ListItem>
                                 <Select
                                    onSelect={(e) => {
                                       doUpdateLesson(
                                          'draft',
                                          e.currentTarget.value
                                       )
                                    }}
                                    defaultValue={props.data.draft}>
                                    <option value={0}>Unpublish</option>
                                    <option value={1}>Publish</option>
                                 </Select>
                              </ListItem>
                              <ListItem>
                                 <InputEditable
                                    doUpdate={doUpdateLesson}
                                    title="Number of Hours"
                                    text={props.data?.duration}
                                 />
                              </ListItem>
                           </List>
                        </SimpleGrid>
                     </Box>
                  </Box>
               </Flex>
               <Stack spacing={{ base: 6, md: 10 }}>
                  <Box as={'header'}>
                     <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}>
                        <InputEditable
                           doUpdate={doUpdateLesson}
                           title="Title"
                           text={props.data.title}
                        />
                     </Heading>
                     <Text
                        color={useColorModeValue('gray.900', 'gray.400')}
                        fontWeight={300}
                        fontSize={'2xl'}>
                        <InputEditable
                           doUpdate={doUpdateLesson}
                           title="Arabic Title"
                           text={props.data.arTitle}
                        />
                     </Text>
                     <Text
                        fontSize={{ base: '16px', lg: '18px' }}
                        color="primary"
                        fontWeight={'500'}
                        mb={'4'}>
                        <InputEditable
                           doUpdate={doUpdateLesson}
                           title="LeadText"
                           text={props.data.leadText}
                        />
                     </Text>
                     <Text
                        fontSize={{ base: '16px', lg: '18px' }}
                        color="primary"
                        fontWeight={'500'}
                        mb={'4'}>
                        <InputEditable
                           doUpdate={doUpdateLesson}
                           title="Arabic LeadText"
                           text={props.data.arLeadText}
                        />
                     </Text>
                  </Box>
                  <Stack
                     spacing={{ base: 4, sm: 6 }}
                     direction={'column'}
                     divider={
                        <StackDivider
                           borderColor={useColorModeValue(
                              'gray.200',
                              'gray.600'
                           )}
                        />
                     }>
                     <VStack spacing={{ base: 4, sm: 6 }}>
                        <EditableCard
                           title="Description"
                           description={currentEditable.Description}
                        />
                        <EditableCard
                           title="Arabic Description"
                           description={currentEditable['Arabic Description']}
                        />
                     </VStack>
                  </Stack>
               </Stack>
            </SimpleGrid>
            <Box className="w-full py-6">
               <Button
                  rounded={'none'}
                  w={'full'}
                  mt={8}
                  size={'lg'}
                  py={'7'}
                  onClick={() => {
                     hundleSave()
                  }}
                  bg={"primary"}
                  color={useColorModeValue('white', 'gray.900')}
                  textTransform={'uppercase'}
                  _hover={{
                     transform: 'translateY(2px)',
                     boxShadow: 'lg'
                  }}>
                  Save Data
               </Button>
            </Box>
         </Container>
      </>
   )
}

export default AdminLessonPlansDatails
