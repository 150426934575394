import { useApiRequests } from 'API/apiRequests'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

export const useGetLessons = () => {
   const { getLessonBySection } = useApiRequests()
   const queryClient = useQueryClient()
   const [lessonData, setLessonData] = useState<any>()

   const { isLoading, mutate, ...attributes } = useMutation(
      getLessonBySection,
      {
         onSuccess: (data) => {
            queryClient.invalidateQueries('lessonBySection')
            setLessonData(data.data.data)
         }
      }
   )
   return { mutate, isLoading, lessonData, attributes }
}
