import { useApiRequests } from 'API/apiRequests'
import { ISliderCardData } from 'Models/SliderCard-type'
import { useMutation, useQueryClient } from 'react-query'
import { useRecoilState } from 'recoil'
import { FilterdData, isEmpty } from 'Recoil/Atoms'

export const useGetFilteredData = () => {
   const [, setData] = useRecoilState(FilterdData)
   const [, setEmpty] = useRecoilState(isEmpty)

   const { getFilterdData } = useApiRequests()
   const FilterdArray: ISliderCardData[] = []

   const queryClient = useQueryClient()

   const { isLoading, mutate } = useMutation(getFilterdData, {
      onSuccess: (data) => {
         queryClient.invalidateQueries('Filtered')
         data.data?.data?.map((item: ISliderCardData[]) => {
            item.map((el: ISliderCardData) => {
               FilterdArray.push(el)
            })
         })
         if (FilterdArray.length === 0) {
            setEmpty(true)
            setData(FilterdArray)
         } else {
            setData(FilterdArray)
         }
      }
   })

   return { isLoading, mutate }
}
