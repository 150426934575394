import {
   Table,
   Thead,
   Tbody,
   Tr,
   Th,
   Td,
   TableContainer,
   Text
} from '@chakra-ui/react'
import LessonModal from 'Components/Modal/LessonModal'
import ButtonsPopOver from 'Components/Popover/ButtonsPopOver'
import { ILessonSection } from 'Models/Lesson-type'
import { takeLastLtters } from 'Utils/VideoPrfix'

interface LessonTableProps {
   data: ILessonSection
}

const LessonTable: React.FunctionComponent<LessonTableProps> = (props) => {
   return (
      <>
         <TableContainer>
            <Table>
               <Thead>
                  <Tr>
                     <Th>ID</Th>
                     <Th>Title</Th>
                     <Th>Ar Title</Th>
                     <Th>Text</Th>
                     <Th>Ar Text</Th>
                     <Th>Created At</Th>
                     <Th>Last Update</Th>
                     <Th>Actions</Th>
                  </Tr>
               </Thead>
               <Tbody>
                  <Tr>
                     <Td>{props.data.id}</Td>
                     <Td>{props.data.title}</Td>
                     <Td>{props.data.arTitle}</Td>
                     <Td>
                        <LessonModal title="Descrption">
                           <Text
                              dangerouslySetInnerHTML={{
                                 __html: props.data.text
                              }}></Text>
                        </LessonModal>
                     </Td>
                     <Td>
                        <LessonModal title="Arabic Descrption">
                           <Text
                              dangerouslySetInnerHTML={{
                                 __html: props.data.arText
                              }}></Text>
                        </LessonModal>
                     </Td>
                     <Td>{takeLastLtters(props.data.created_at)}</Td>
                     <Td>{takeLastLtters(props.data.updated_at)}</Td>
                     <Td>
                        <ButtonsPopOver
                           arText={props.data.arText}
                           arTitle={props.data.arTitle}
                           text={props.data.text}
                           title={props.data.title}
                           id={props.data.id}
                        />
                     </Td>
                  </Tr>
               </Tbody>
            </Table>
         </TableContainer>
      </>
   )
}

export default LessonTable
