import { Button, Text } from '@chakra-ui/react'
import { useAddEnroll } from 'Hooks/queries/useAddEnroll'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { isLogin } from 'Utils/HelperMethods'

interface CourseCardProps {
   imgThumbnail: string
   hours?: number
   downloadable?: number
   id: number
   link: string
   isEnrolled: boolean
}

const CourseCard: React.FunctionComponent<CourseCardProps> = (props) => {
   const navigate = useNavigate()
   const { t } = useTranslation('translation')
   const { isLoading, mutate } = useAddEnroll()

   return (
      <>
         <img src={props.imgThumbnail} loading="lazy" className="w-full" />
         {isLogin === undefined ? (
            <Button
               w="50%"
               isLoading={isLoading}
               onClick={() => {
                  navigate('../login')
               }}
               variant="primary">
               {t('enroll')}
            </Button>
         ) : (
            <>
               {!props.isEnrolled ? (
                  <>
                     <Button
                        w="50%"
                        className="mt-4"
                        isLoading={isLoading}
                        onClick={() => {
                           mutate(props.id)
                        }}
                        variant="primary">
                        {t('enroll')}
                     </Button>
                  </>
               ) : (
                  <Button
                     onClick={() => navigate(`../playing/${props.link}`)}
                     className="no-underline mt-4"
                     w="50%"
                     bgColor="dark-blue"
                     _active={{ bgColor: 'dark-blue' }}
                     variant="primary">
                     {t('view_now')}
                  </Button>
               )}
            </>
         )}

         <Text className="text-xl text-left lg:px-4">
            {t('course_includes')}
         </Text>
      </>
   )
}

export default CourseCard
