import {
   Button,
   Center,
   Flex,
   Heading,
   Image,
   Stack,
   useColorModeValue
} from '@chakra-ui/react'
import { ICategoriesData } from 'Models/Categories-type'

interface CategoryCardProps {
   data: ICategoriesData
}

const CategoryCard: React.FunctionComponent<CategoryCardProps> = (props) => {
   return (
      <Center key={props.data.id} py={6}>
         <Stack
            borderWidth="1px"
            borderRadius="lg"
            w={{ sm: '100%', md: '540px' }}
            height={{ sm: '476px', md: '20rem' }}
            direction={{ base: 'column', md: 'row' }}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'2xl'}
            padding={4}>
            <Flex flex={1} bg="blue.200">
               <Image objectFit="cover" boxSize="100%" src={props.data.img} />
            </Flex>
            <Stack
               flex={1}
               flexDirection="column"
               justifyContent="center"
               alignItems="center"
               p={1}
               pt={2}>
               <Heading fontSize={'2xl'}>{props.data.title}</Heading>
               <Stack
                  width={'100%'}
                  mt={'2rem'}
                  direction={'row'}
                  padding={2}
                  justifyContent={'space-between'}
                  alignItems={'center'}>
                  <Button variant="adminCard" color="white" bg="red.400">
                     Delete
                  </Button>
                  <Button variant="adminCard" bg="primary" color="white">
                     Edit
                  </Button>
               </Stack>
            </Stack>
         </Stack>
      </Center>
   )
}

export default CategoryCard
