import {
   Box,
   Button,
   Drawer,
   DrawerBody,
   DrawerContent,
   DrawerOverlay,
   useDisclosure
} from '@chakra-ui/react'
import SearchInput from 'Components/Inputs/SearchInput'
import { useTranslation } from 'react-i18next'
import { AiOutlineMenu } from 'react-icons/ai'
import { Link, useLocation } from 'react-router-dom'
import { handleChangeLang, isLogin } from 'Utils/HelperMethods'

interface MobileDrawerProps {}

const MobileDrawer: React.FunctionComponent<MobileDrawerProps> = () => {
   const { t } = useTranslation('translation')
   const { isOpen, onOpen, onClose } = useDisclosure()
   const location = useLocation()

   return (
      <>
         <AiOutlineMenu className="hidden lg:block text-4xl" onClick={onOpen} />
         <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent>
               <DrawerBody>
                  <Box className="flex flex-col w-full items-start gap-y-3 py-2">
                     <Link to="../">
                        <Button variant="unstyled">{t('Explore')}</Button>
                     </Link>
                     <Box className="flex gap-x-2 justify-between">
                        {isLogin === undefined && (
                           <Button className="border" variant="ghost">
                              <Link to="../login">
                                 <span className="lg:text-sm">
                                    {t('sign_in')}
                                 </span>
                              </Link>
                           </Button>
                        )}
                        <Button
                           onClick={() => {
                              handleChangeLang('en')
                           }}
                           variant="blank">
                           En
                        </Button>
                        <Button
                           onClick={() => {
                              handleChangeLang('ar')
                           }}
                           variant="blank">
                           عربي
                        </Button>
                     </Box>
                     {location.pathname === '/' && (
                        <SearchInput isDrawer={true} />
                     )}
                  </Box>
               </DrawerBody>
            </DrawerContent>
         </Drawer>
      </>
   )
}

export default MobileDrawer
