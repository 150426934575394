import { extendTheme } from '@chakra-ui/react'
import { getCurrentFont } from 'Utils/HelperMethods'
export const theme = extendTheme({
   fonts: getCurrentFont(),
   styles: {
      global: {
         h1: {
            fontSize: '2xl',
            fontWeight: 'bold'
         },
         h3: {
            fontSize: 'lg'
         },
         h4: {
            fontSize: 'md'
         }
      }
   },
   components: {
      Button: {
         baseStyle: { _focus: { boxShadow: 'none' } },
         variants: {
            primary: () => ({
               bg: 'primary',
               borderRadius: '0',
               paddingInline: '6',
               fontWeight: '400',
               color: 'white',
               w: 'full',
               _disabled: {
                  opacity: 0.5
               },
               _hover: {
                  opacity: 0.8
               },
               _active: { bg: 'primary', opacity: '0.7' }
            }),
            ghost: () => ({
               bg: 'white',
               border: '1',
               borderRadius: '0',
               paddingInline: '6',
               fontWeight: '400',
               color: 'light-gray',
               w: 'full',
               _disabled: {
                  bgColor: 'white',
                  opacity: 0.5
               },
               _active: { bg: 'primary', opacity: '0.7' }
            }),
            blank: () => ({
               bg: 'white',
               fontWeight: '400',
               w: 'full',
               _disabled: {
                  bgColor: 'primary',
                  opacity: 0.5
               },
               _active: { bg: 'white', opacity: '0.7' }
            }),
            courses: () => ({
               bg: 'dark-red',
               color: 'white',
               fontWeight: '400',
               h: '8',
               paddingInline: '6',
               borderRadius: '0',
               _disabled: {
                  bgColor: 'primary',
                  opacity: 0.5
               },
               _active: { bg: 'dark-red', opacity: '0.7' }
            }),
            video: () => ({
               bg: 'dark-yallow',
               color: 'light-blue',
               paddingInline: '6',
               fontWeight: '400',
               h: '8',
               borderRadius: '0',
               _disabled: {
                  bgColor: 'primary',
                  opacity: 0.5
               },
               _active: { bg: 'dark-yallow', opacity: '0.7' }
            }),
            LessonPlans: () => ({
               bg: 'primary',
               color: 'white',
               paddingInline: '6',
               fontWeight: '400',
               h: '8',
               borderRadius: '0',
               _disabled: {
                  bgColor: 'primary',
                  opacity: 0.5
               },
               _active: { bg: 'primary', opacity: '0.7' }
            }),
            adminCard: () => ({
               flex: '1',
               rounded: 'full',
               _hover: {
                  opacity: '0.7'
               },
               _focus: {
                  opacity: '0.7'
               },
               boxShadow:
                  '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
            })
         }
      }
   },
   colors: {
      primary: '#79a32e',
      'light-gray': '#707070',
      'dark-gray': '#f7f9fa',
      'light-blue': '#023a51',
      'dark-blue': '#073f55',
      'light-green': '#ebf1e0',
      footer: 'rgb(196, 196, 196)',
      'border-gray': '#dbdbdb',
      'dark-red': '#d60005',
      'dark-yallow': '#ffd600',
      green: {
         500: '#79a32e'
      }
   },
   zIndices: {
      modal: 1000,
      overlay: 1000
   }
})
