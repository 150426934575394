import { IVideos } from 'Models/Videos-type'
import { useRecoilState } from 'recoil'
import { CurrentEditVideo } from 'Recoil/Atoms'

const useSetVideoData = () => {
   const [, setCurrentEditable] = useRecoilState(CurrentEditVideo)
   const setDefualtData = (data: IVideos) => {
      setCurrentEditable({
        Title: data.title,
        'Arabic Title': data.arTitle,
        VideoID: data.VideoURL,
        LeadText: data.leadText,
        'Arabic LeadText': data.arLeadText,
        Category: data.category_id,
        Level: data.level_id,
        Status: data.draft
      })
   }

   return { setDefualtData }
}

export default useSetVideoData
