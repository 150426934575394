import ReactGA from 'react-ga4'
import { InitOptions } from 'react-ga4/types/ga4'
/* this is for initialize GA setup */
export const initGA = (trackingId: any | InitOptions[]) => {
   ReactGA.initialize(trackingId)
}
/* this is for getting url website when user access our websites */
export const PageView = () => {
   ReactGA.pageview(window.location.pathname + window.location.search)
}

declare global {
   interface Window {
      dataLayer: any
   }
}

/**
 * Event - Add custom tracking event.
 * This's for tracking when event clicked something like that they'll send the data to GA
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */

export const Event = (evenName: string, label: string) => {
   window.dataLayer = window.dataLayer || []
   window.dataLayer.push({
      event: evenName,
      videoname: label
   })
}
