import { Box, Button, List, Text } from '@chakra-ui/react'
import { ISliderCardData } from 'Models/SliderCard-type'
import IconsLists from 'Components/Lists/IconsLists'
import CourseCard from 'Components/Cards/CourseCard'
import { currentLang, isLogin } from 'Utils/HelperMethods'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { BsDownload } from 'react-icons/bs'
import DescriptionCard from 'Components/Cards/DescriptionCard'
import { useEffect, useState } from 'react'
import useEnrolledCourse from 'Hooks/useEnrolledCourse'
import { EnrolledCourese } from 'Recoil/Atoms'
import { useRecoilState } from 'recoil'
import { Event } from 'Utils/Tracking'

interface CourseDetailsProps {
   data: ISliderCardData
}

const CourseDetails: React.FunctionComponent<CourseDetailsProps> = (props) => {
   const { checkEnrollCourse } = useEnrolledCourse()
   const [enrolledCourses] = useRecoilState(EnrolledCourese)
   const [isEnrolled, setisEnrolled] = useState(false)

   useEffect(() => {
      if (isLogin !== undefined) {
         if (checkEnrollCourse(props.data.id, enrolledCourses)) {
            setisEnrolled(true)
         }
      }
      Event('course_clicked', props.data.title)
   }, [])

   return (
      <>
         <Box className="bg-light-blue pt-8 max-h-[23rem] lg:max-h-full lg:items-start lg:py-8 justify-around flex w-full lg:flex-col">
            <Box className="py-12 w-1/2 flex flex-col gap-y-5 lg:px-6 lg:w-full items-start lg:items-start">
               <Button cursor="default" variant="courses">
                  {currentLang === 'en' ? (
                     <> {props.data.type}</>
                  ) : (
                     <> {props.data.arType}</>
                  )}
               </Button>
               <Text className="text-2xl  text-primary font-normal">
                  {currentLang === 'en' ? (
                     <> {props.data.title}</>
                  ) : (
                     <> {props.data.arTitle}</>
                  )}
               </Text>
               <Text className="text-lg  text-white font-light">
                  {currentLang === 'en' ? (
                     <> {props.data.leadText}</>
                  ) : (
                     <> {props.data.arLeadText}</>
                  )}
               </Text>
            </Box>
            <Box className="bg-white rounded-lg relative top-8 flex min-h-[33rem] lg:self-center flex-col gap-y-6 items-center w-1/4 lg:w-2/3 lg:static lg:min-h-full shadow-lg lg:pb-4">
               <CourseCard
                  isEnrolled={isEnrolled}
                  imgThumbnail={props.data.imgThumbnail}
                  id={props.data.id}
                  link={props.data.link}
               />
               <List paddingX="2" spacing={4}>
                  <IconsLists
                     icon={AiOutlineClockCircle}
                     name="hours-on-demand-video"
                     data={props.data.hours}
                  />
                  <IconsLists
                     icon={BsDownload}
                     name="downloadable_resources"
                     data={props.data.downloadable}
                  />
               </List>
            </Box>
         </Box>
         <Box className="w-4/6 p-8 lg:w-full min-h-[50vh]">
            <Box className="rounded-lg shadow-lg border bg-white p-12">
               {currentLang === 'en' ? (
                  <DescriptionCard description={props.data.description} />
               ) : (
                  <DescriptionCard description={props.data.arDescription} />
               )}
            </Box>
         </Box>
      </>
   )
}

export default CourseDetails
