import { Input, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useSearchByID } from 'Hooks/queries/useSearchByID'
import { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { FilterdData } from 'Recoil/Atoms'
import { useRecoilState } from 'recoil'

interface SearchInputProps {
   isDrawer?: boolean
}

const SearchInput: React.FunctionComponent<SearchInputProps> = (props) => {
   const { t } = useTranslation('translation')
   const [, setData] = useRecoilState(FilterdData)
   const [search, setSearch] = useState('')
   const { mutate, isLoading } = useSearchByID()

   const hundleSearch = debounce((e) => {
      setSearch(e)
   }, 2000)
   useEffect(() => {
      if (search !== '') {
         mutate(search)
      } else {
         setData([])
      }
   }, [search])

   return (
      <>
         {props.isDrawer !== undefined ? (
            <>
               {isLoading && (
                  <Spinner
                     thickness="4px"
                     speed="0.65s"
                     emptyColor="gray.200"
                     color="primary"
                     size="md"
                  />
               )}
               <Input
                  onChange={(e) => {
                     hundleSearch(e.target.value)
                  }}
                  className=""
                  rounded="lg"
                  w="80%"
                  placeholder={t('seatch_for')}
                  disabled={isLoading}
               />
            </>
         ) : (
            <>
               {isLoading && (
                  <Spinner
                     thickness="4px"
                     speed="0.65s"
                     emptyColor="gray.200"
                     color="primary"
                     size="md"
                  />
               )}
               <Input
                  onChange={(e) => {
                     hundleSearch(e.target.value)
                  }}
                  className="lg:hidden"
                  rounded="lg"
                  w="40%"
                  placeholder={t('seatch_for')}
                  disabled={isLoading}
               />
            </>
         )}
      </>
   )
}

export default SearchInput
