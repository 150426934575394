import { Box, Button, Text } from '@chakra-ui/react'
import SearchInput from 'Components/Inputs/SearchInput'
import MobileDrawer from 'Components/Menu/MobileDrawer'
import ProfileMenu from 'Components/Menu/ProfileMenu'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { currentLang, handleChangeLang, isAdmin } from 'Utils/HelperMethods'

interface LoginNavbarProps {}

const LoginNavbar: React.FunctionComponent<LoginNavbarProps> = () => {
   const { t } = useTranslation('translation')
   const location = useLocation()

   return (
      <>
         <Box className="flex z-50 items-center justify-between gap-x-8 py-3 px-8 shadow-xl sticky">
            <Box className="flex items-center gap-x-6 lg:gap justify-center">
               {currentLang === 'en' ? (
                  <>
                     <img
                        src="/Images\horiziontal-thaki-logo-white-bg-web.png"
                        className="h-12 cursor-pointer"
                        alt="Logo"
                        onClick={() => {
                           window.location.replace('/')
                        }}
                     />
                  </>
               ) : (
                  <>
                     <img
                        src="/Images\TDT-logo-Arabic.png"
                        className="h-12 cursor-pointer"
                        alt="Logo"
                        onClick={() => {
                           window.location.replace('/')
                        }}
                     />
                  </>
               )}

               <Box className="lg:hidden">
                  <Link to="../">
                     <Button variant="unstyled">{t('Explore')}</Button>
                  </Link>
               </Box>
            </Box>

            {location.pathname === '/' && <SearchInput />}
            <Box className="flex">
               <Box className="flex gap-x-2 lg:gap-x-1 justify-between lg:hidden px-16">
                  <Button
                     className="border-r"
                     onClick={() => {
                        handleChangeLang('en')
                     }}
                     variant="blank">
                     En
                  </Button>
                  <Button
                     onClick={() => {
                        handleChangeLang('ar')
                     }}
                     variant="blank">
                     عربي
                  </Button>
               </Box>
               <Box className="flex items-center">
                  <Box className="border-x h-full px-6 lg:px-1 flex items-center">
                     {isAdmin !== undefined ? (
                        <>
                           <Link to="../admin">
                              <Text className="text-center text-md px-6 font-semibold text-light-gray">
                                 {t('Admin')}
                              </Text>
                           </Link>
                        </>
                     ) : (
                        <Link to="../my-courses">
                           <Text className="text-center text-md px-6 lg:px-1 lg:text-xs font-semibold text-light-gray">
                              {t('My_Courses')}
                           </Text>
                        </Link>
                     )}
                  </Box>
                  <ProfileMenu />
               </Box>
               <MobileDrawer />
            </Box>
         </Box>
      </>
   )
}

export default LoginNavbar
