import { useRecoilState } from 'recoil'
import { CurrentUpdatedImage } from 'Recoil/Atoms'

const useFillFormData = () => {
   const [currentImage] = useRecoilState(CurrentUpdatedImage)

   const doFillCourse = (fields: any) => {
      if (currentImage !== '') {
         const formdata = new FormData()
         formdata.append('title', fields.Title)
         formdata.append('arTitle', fields['Arabic Title'])
         formdata.append('leadText', fields.LeadText)
         formdata.append('arLeadText', fields['Arabic LeadText'])
         formdata.append('imgThumbnail', currentImage)
         formdata.append('description', fields.Description)
         formdata.append('arDescription', fields['Arabic Description'])
         formdata.append('hours', fields['Number of Hours'])
         formdata.append('downloadable', fields['Number of Downloadable'])
         formdata.append('draft', fields.Status)
         formdata.append(
            'promoVideoURL',
            'https://www.youtube.com/embed/tPTPpjOWhsw'
         )
         formdata.append('category_id', fields.Category)
         formdata.append('level_id', fields.Level)
         formdata.append('type_id', '1')
         return formdata
      } else {
         const formdata = new FormData()
         formdata.append('title', fields.Title)
         formdata.append('arTitle', fields['Arabic Title'])
         formdata.append('leadText', fields.LeadText)
         formdata.append('arLeadText', fields['Arabic LeadText'])
         formdata.append('description', fields.Description)
         formdata.append('arDescription', fields['Arabic Description'])
         formdata.append('hours', fields['Number of Hours'])
         formdata.append('downloadable', fields['Number of Downloadable'])
         formdata.append('draft', fields.Status)
         formdata.append(
            'promoVideoURL',
            'https://www.youtube.com/embed/tPTPpjOWhsw'
         )
         formdata.append('category_id', fields.Category)
         formdata.append('level_id', fields.Level)
         formdata.append('type_id', '1')
         return formdata
      }
   }

   const doFillLessonPlan = (fields: any) => {
      if (currentImage !== undefined) {
         const formdata = new FormData()
         formdata.append('title', fields.Title)
         formdata.append('arTitle', fields['Arabic Title'])
         formdata.append('imgThumbnail', currentImage)
         formdata.append('leadText', fields.LeadText)
         formdata.append('arLeadText', fields['Arabic LeadText'])
         formdata.append('description', fields.Description)
         formdata.append('arDescription', fields['Arabic Description'])
         formdata.append('duration', fields.Duration)
         formdata.append('age', fields.Age)
         formdata.append('draft', fields.Status)
         formdata.append('LP_URL', fields['File URL'])
         formdata.append('arLP_URL', fields['Arabic File URL'])
         formdata.append('category_id', fields.Category)
         formdata.append('level_id', fields.Level)
         formdata.append('type_id', '2')
         return formdata
      } else {
         const formdata = new FormData()
         formdata.append('title', fields.Title)
         formdata.append('arTitle', fields['Arabic Title'])
         formdata.append('leadText', fields.LeadText)
         formdata.append('arLeadText', fields['Arabic LeadText'])
         formdata.append('description', fields.Description)
         formdata.append('arDescription', fields['Arabic Description'])
         formdata.append('duration', fields.Duration)
         formdata.append('age', fields.Age)
         formdata.append('draft', fields.Status)
         formdata.append('LP_URL', fields['File URL'])
         formdata.append('arLP_URL', fields['Arabic File URL'])
         formdata.append('category_id', fields.Category)
         formdata.append('level_id', fields.Level)
         formdata.append('type_id', '2')
         return formdata
      }
   }

   const doFillVideo = (fields: any) => {
      const formdata = new FormData()
      formdata.append('title', fields.Title)
      formdata.append('arTitle', fields['Arabic LeadText'])
      formdata.append('VideoURL', fields.VideoURL)
      formdata.append('imgThumbnail', fields.imgThumbnail)
      formdata.append('leadText', fields.LeadText)
      formdata.append('arLeadText', fields['Arabic LeadText'])
      formdata.append('draft', fields.Status)
      formdata.append('category_id', fields.Category)
      formdata.append('level_id', fields.Level)
      formdata.append('type_id', '3')
   }

   return { doFillCourse, doFillLessonPlan, doFillVideo }
}

export default useFillFormData
