import { Box } from '@chakra-ui/react'
import SliderCard from 'Components/Cards/SliderCard'
import { ISliderCardData } from 'Models/SliderCard-type'

interface FilteredCardProps {
   FilterdData: ISliderCardData
}

const FilteredCard: React.FunctionComponent<FilteredCardProps> = (props) => {
   return (
      <Box
         key={props.FilterdData.id}
         className="lg:w-3/4 lg:h-full w-3/12 m-8 shadow-lg">
         <SliderCard data={props.FilterdData} />
      </Box>
   )
}

export default FilteredCard
