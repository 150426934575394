import { useApiRequests } from 'API/apiRequests'
import { useMutation, useQueryClient } from 'react-query'
import { useRecoilState } from 'recoil'
import { CurrentSections } from 'Recoil/Atoms'

export const useGetSections = () => {
   const { getCoursesSections } = useApiRequests()
   const queryClient = useQueryClient()
   const [, setSections] = useRecoilState(CurrentSections)

   const { isLoading, mutate, ...attributes } = useMutation(
      getCoursesSections,
      {
         onSuccess: (data) => {
            queryClient.invalidateQueries('Sections')
            setSections(data.data.data)
         }
      }
   )

   return { mutate, isLoading, attributes }
}
