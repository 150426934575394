import { Box, Button, Skeleton, Text } from '@chakra-ui/react'
import { ISliderCardData } from 'Models/SliderCard-type'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { currentLang } from 'Utils/HelperMethods'

interface SliderCardProps {
   data: ISliderCardData
   fromMyCourse?: boolean
}

const SliderCard: React.FunctionComponent<SliderCardProps> = (props) => {
   const { t } = useTranslation('translation')

   return (
      <>
         <Skeleton key={props.data.id} isLoaded>
            <Box className="flex flex-col items-center justify-between bg-white border">
               <Link to={`../${props.data.link}`}>
                  <img
                     loading="lazy"
                     src={props.data.imgThumbnail}
                     className="w-96"
                  />
               </Link>
               {currentLang === 'en' ? (
                  <Text className="text-center pt-2 text-md min-h-[3.3rem]">
                     {props.data.title}
                  </Text>
               ) : (
                  <Text className="text-center pt-2 text-md min-h-[3.3rem]">
                     {props.data.arTitle}
                  </Text>
               )}
               <Box className="py-4">
                  {props.fromMyCourse ? (
                     <Link to={`../playing/${props.data.link}`}>
                        {props.data.type_id === 1 && (
                           <Button variant="courses">{t('Course')}</Button>
                        )}
                        {props.data.type === 'Videos' && (
                           <Button variant="video">{t('Video')}</Button>
                        )}
                        {props.data.type === 'Lesson Plans' && (
                           <Button variant="LessonPlans">{t('lesson')}</Button>
                        )}
                     </Link>
                  ) : (
                     <Link to={`../${props.data.link}`}>
                        {props.data.type_id === 1 && (
                           <Button variant="courses">{t('Course')}</Button>
                        )}
                        {props.data.type === 'Videos' && (
                           <Button variant="video">{t('Video')}</Button>
                        )}
                        {props.data.type === 'Lesson Plans' && (
                           <Button variant="LessonPlans">{t('lesson')}</Button>
                        )}
                     </Link>
                  )}
               </Box>
            </Box>
         </Skeleton>
      </>
   )
}

export default SliderCard
