import { Box } from '@chakra-ui/react'
import CategoryCard from 'Components/Cards/AdminCards/CategoryCard'
import { useGetCategories } from 'Hooks/queries/useGetCategories'
import { ICategoriesData } from 'Models/Categories-type'

interface CategoryPanelProps {}

const CategoryPanel: React.FunctionComponent<CategoryPanelProps> = () => {
   const Categories = useGetCategories()

   return (
      <>
         <Box className="flex flex-wrap gap-4 px-6 items-center justify-center">
            {Categories.Categories.map((item: ICategoriesData) => (
               <CategoryCard data={item} />
            ))}
         </Box>
      </>
   )
}

export default CategoryPanel
