import { Box, Button, Input, Text } from '@chakra-ui/react'
import { useRequestNewPassword } from 'Hooks/queries/useRequestNewPassword'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ForgetPasswordProps {}

const ForgetPassword: React.FunctionComponent<ForgetPasswordProps> = () => {
   const { t } = useTranslation('translation')
   const [email, setemail] = useState('')
   const { mutate, isLoading } = useRequestNewPassword()
   return (
      <>
         <Box className="flex flex-col justify-center items-center w-full overflow-x-hidden">
            <Box className="w-11/12 py-20 lg:py-6">
               <Text className="text-primary font-semibold text-2xl py-12 lg:py-6 text-center">
                  {t('log_in_to_your')}
               </Text>
               <Box className="bg-white shadow-2xl py-8 flex flex-col items-center">
                  <Box className="w-1/2 lg:w-9/12 flex flex-col items-center">
                     <Text className="py-8">{t('To_reset')}</Text>
                     <Input
                        onChange={(e) => {
                           setemail(e.target.value)
                        }}
                        className="my-12"
                        variant="filled"
                        placeholder={t('enter_email')}
                        type="email"
                     />
                     <Button
                        bgColor="primary"
                        color="white"
                        isLoading={isLoading}
                        onClick={() => mutate(email)}>
                        {t('reset_password')}
                     </Button>
                  </Box>
               </Box>
            </Box>
         </Box>
      </>
   )
}

export default ForgetPassword
