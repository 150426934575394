interface DescriptionCardProps {
   description: string
}

const DescriptionCard: React.FunctionComponent<DescriptionCardProps> = (
   props
) => {
   return (
      <>
         <div
            className="list-disc"
            dangerouslySetInnerHTML={{
               __html: props.description
            }}></div>
      </>
   )
}

export default DescriptionCard
