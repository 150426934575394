import { useRecoilState } from 'recoil'
import { SelectedData } from 'Recoil/Atoms'

export interface ISeletedData {
   Category: number
   Type: number
}

export const useChangeFilter = () => {
   const [, setData] = useRecoilState(SelectedData)

   const checkSelect = (id: number, type: string, data: ISeletedData) => {
      const newData = { ...data }
      if (type === 'Categories') {
         newData.Category = id
      }
      if (type === 'Type') {
         newData.Type = id
      }
      setData(newData)
   }

   return { checkSelect }
}
