import { ISeletedData } from 'Hooks/queries/useChangeFilter'
import { ICourseData } from 'Hooks/useEnrolledCourse'
import Cookies from 'js-cookie'
import { ILesson, IPlayingCourse } from 'Models/Section-type'
import { ISliderCardData } from 'Models/SliderCard-type'
import { IUserData } from 'Models/UserData-type'
import { atom } from 'recoil'
export const Token = Cookies.get('token')

export const FilterdData = atom<ISliderCardData[]>({
   key: 'FilterdData',
   default: []
})

export const SelectedData = atom<ISeletedData>({
   key: 'SelectedData',
   default: {
      Category: 0,
      Type: 0
   }
})

export const isEmpty = atom({
   key: 'isEmpty',
   default: false
})

export const isLogin = atom({
   key: 'isLogin',
   default: Token
})

export const EnrolledCourese = atom<ICourseData[]>({
   key: 'EnrolledCourese',
   default: []
})

export const CurrentCourse = atom<ILesson[][]>({
   key: 'CurrentCourse',
   default: []
})

export const CurrentText = atom({
   key: 'CurrentText',
   default: {
      text: '',
      id: 0
   }
})

export const CurrentPageIndex = atom({
   key: 'CurrentPageIndex',
   default: 0
})

export const CurrentSectionIndex = atom({
   key: 'CurrentSectionIndex',
   default: 0
})

export const UserData = atom<IUserData>({
   key: 'UserData',
   default: {
      active: 0,
      birthday: '',
      country: '',
      email: '',
      email_verified_at: '',
      fullName: '',
      gender: '',
      id: 0,
      prifLang: '',
      profileImage: '',
      role_id: 0
   }
})

export const CurrentAdminView = atom({
   key: 'CurrentAdminView',
   default: ''
})

export const CurrentEditCourse = atom({
   key: 'CurrentEditCourse',
   default: {
      Title: '',
      'Arabic Title': '',
      LeadText: '',
      'Arabic LeadText': '',
      Thumbnail: '',
      Description: '',
      'Arabic Description': '',
      'Number of Hours': 0,
      'Number of Downloadable': 0,
      Status: 0,
      Category: 0,
      Level: 0
   }
})

export const CurrentEditLessonPlan = atom({
   key: 'CurrentEditLessonPlan',
   default: {
      Title: '',
      'Arabic Title': '',
      Thumbnail: '',
      LeadText: '',
      'Arabic LeadText': '',
      Description: '',
      'Arabic Description': '',
      Duration: '',
      Age: 0,
      Status: 0,
      'File URL': '',
      'Arabic File URL': '',
      Category: 0,
      Level: 0
   }
})

export const CurrentEditVideo = atom({
   key: 'CurrentEditVideo',
   default: {
      Title: '',
      'Arabic Title': '',
      VideoID: '',
      LeadText: '',
      'Arabic LeadText': '',
      Category: 0,
      Level: 0,
      Status: 0
   }
})

export const CurrentSections = atom({
   key: 'CurrentSections',
   default: []
})

export const CurrentUpdatedImage = atom({
   key: 'CurrentUpdatedImage',
   default: '' as any
})
