import {
   Box,
   Button,
   Container,
   Flex,
   Heading,
   Select,
   List,
   ListItem,
   SimpleGrid,
   Stack,
   StackDivider,
   Text,
   useColorModeValue,
   Image
} from '@chakra-ui/react'
import InputEditable from 'Components/Inputs/InputEditable'
import VideoInputEditable from 'Components/Inputs/VideoInputEditable'
import { useUpdateVideo } from 'Hooks/queries/Admin/Videos/useUpdateVideo'
import useFillFormData from 'Hooks/useFillFormData'
import useSetVideoData from 'Hooks/useSetVideoData'
import useUpdateEditeCourse from 'Hooks/useUpdateEditeCourse'
import { IVideos } from 'Models/Videos-type'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { CurrentEditVideo } from 'Recoil/Atoms'
import { getVideoId } from 'Utils/VideoPrfix'

interface AdminVideoDeailsProps {
   data: IVideos
}

const AdminVideoDeails: React.FunctionComponent<AdminVideoDeailsProps> = (
   props
) => {
   const [currentEditable] = useRecoilState(CurrentEditVideo)
   const { setDefualtData } = useSetVideoData()
   const { doUpdateVideo } = useUpdateEditeCourse()
   const { doFillVideo } = useFillFormData()
   const updateVideo = useUpdateVideo()

   useEffect(() => {
      setDefualtData(props.data)
   }, [])

   const hundleSave = () => {
      const formData = doFillVideo(currentEditable)
      const mutateData = {
         data: formData,
         id: props.data.id
      }
      updateVideo.mutate(mutateData)
   }

   return (
      <>
         <Container maxW={'8xl'}>
            <SimpleGrid
               columns={{ base: 1, lg: 2 }}
               spacing={{ base: 8, md: 10 }}
               py={{ base: 18, md: 24 }}>
               <Flex gap="20" direction="column">
                  <Image src={props.data.imgThumbnail} />
                  <Box>
                     <Text className="text-3xl font-semibold pb-8">
                        Sections
                     </Text>
                     <Box>
                        <Text
                           fontSize={{ base: '16px', lg: '18px' }}
                           color="primary"
                           fontWeight={'500'}
                           textTransform={'uppercase'}
                           mb={'4'}>
                           Details
                        </Text>

                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                           <List spacing={2}>
                              <ListItem>Video ID</ListItem>
                              <ListItem>Status</ListItem>
                           </List>
                           <List spacing={2}>
                              <ListItem>
                                 <VideoInputEditable
                                    doUpdate={doUpdateVideo}
                                    title="VideoID"
                                    text={getVideoId(props.data?.VideoURL)}
                                 />
                              </ListItem>
                              <ListItem>
                                 <Select
                                    onSelect={(e) => {
                                       doUpdateVideo(
                                          'draft',
                                          e.currentTarget.value
                                       )
                                    }}
                                    defaultValue={props.data.draft}>
                                    <option value={0}>Unpublish</option>
                                    <option value={1}>Publish</option>
                                 </Select>
                              </ListItem>
                           </List>
                        </SimpleGrid>
                     </Box>
                  </Box>
               </Flex>
               <Stack spacing={{ base: 6, md: 10 }}>
                  <Box as={'header'}>
                     <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}>
                        <InputEditable
                           doUpdate={doUpdateVideo}
                           title="Title"
                           text={props.data.title}
                        />
                     </Heading>
                     <Text
                        color={useColorModeValue('gray.900', 'gray.400')}
                        fontWeight={300}
                        fontSize={'2xl'}>
                        <InputEditable
                           doUpdate={doUpdateVideo}
                           title="Arabic Title"
                           text={props.data.arTitle}
                        />
                     </Text>
                     <Text
                        fontSize={{ base: '16px', lg: '18px' }}
                        color="primary"
                        fontWeight={'500'}
                        mb={'4'}>
                        <InputEditable
                           doUpdate={doUpdateVideo}
                           title="LeadText"
                           text={props.data.leadText}
                        />
                     </Text>
                     <Text
                        fontSize={{ base: '16px', lg: '18px' }}
                        color="primary"
                        fontWeight={'500'}
                        mb={'4'}>
                        <InputEditable
                           doUpdate={doUpdateVideo}
                           title="Arabic LeadText"
                           text={props.data.arLeadText}
                        />
                     </Text>
                  </Box>
                  <Stack
                     spacing={{ base: 4, sm: 6 }}
                     direction={'column'}
                     divider={
                        <StackDivider
                           borderColor={useColorModeValue(
                              'gray.200',
                              'gray.600'
                           )}
                        />
                     }></Stack>
               </Stack>
            </SimpleGrid>
            <Box className="w-full py-6">
               <Button
                  rounded={'none'}
                  w={'full'}
                  mt={8}
                  size={'lg'}
                  py={'7'}
                  onClick={() => {
                     hundleSave()
                  }}
                  bg={'primary'}
                  color={useColorModeValue('white', 'gray.900')}
                  textTransform={'uppercase'}
                  _hover={{
                     transform: 'translateY(2px)',
                     boxShadow: 'lg'
                  }}>
                  Save Data
               </Button>
            </Box>
         </Container>
      </>
   )
}

export default AdminVideoDeails
