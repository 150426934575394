import { Editable, EditableInput, EditablePreview } from '@chakra-ui/react'
import { useEffect } from 'react'

interface EditableInputProps {
   text: string
   title: string
   doUpdate: (e: string, text: string) => void
}

const InputEditable: React.FunctionComponent<EditableInputProps> = (props) => {
   useEffect(() => {
      props.doUpdate(props.title, props.text)
   }, [props.title])

   return (
      <>
         <Editable
            onSubmit={(e) => {
               props.doUpdate(props.title, e)
            }}
            textAlign="center"
            defaultValue={props.text}>
            <EditablePreview />
            <EditableInput />
         </Editable>
      </>
   )
}

export default InputEditable
