import { ISliderCardData } from 'Models/SliderCard-type'
import { useRecoilState } from 'recoil'
import { CurrentEditCourse } from 'Recoil/Atoms'

const useSetRenderData = () => {
   const [, setCurrentEditable] = useRecoilState(CurrentEditCourse)

   const setDefualtData = (data: ISliderCardData) => {
      setCurrentEditable({
         Title: data.title,
         'Arabic Title': data.arTitle,
         LeadText: data.leadText,
         'Arabic LeadText': data.arLeadText,
         Thumbnail: data.imgThumbnail,
         Description: data.description,
         'Arabic Description': data.arDescription,
         'Number of Hours': data.hours,
         'Number of Downloadable': data.downloadable,
         Status: data.draft,
         Category: data.category_id,
         Level: data.level_id
      })
   }

   return { setDefualtData }
}

export default useSetRenderData
