import { Box, Text } from '@chakra-ui/react'
import RegisterForm from 'Components/Forms/RegisterForm'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { isLogin } from 'Utils/HelperMethods'

interface RegisterProps {}

const Register: React.FunctionComponent<RegisterProps> = () => {
   const { t } = useTranslation('translation')
   const navigate = useNavigate()

   useEffect(() => {
      if (isLogin !== undefined) {
         navigate('../my-courses')
      }
   }, [])

   return (
      <>
         <Box className="flex flex-col justify-center lg:min-h-screen items-center w-full">
            <Box className="w-11/12 py-20 lg:py-6">
               <Text className="text-primary font-semibold text-2xl py-12 lg:py-6 text-center">
                  {t('reg_new_acc')}
               </Text>
               <Box className="bg-white shadow-2xl flex">
                  <Box className="w-full h-full lg:hidden">
                     <img src="Images\man-login.png" />
                  </Box>
                  <Box className="w-full flex flex-col justify-center border-r border-l items-center">
                     <RegisterForm />
                     <Text className="py-2">
                        {t('Do-you-have-an-account-?')}{' '}
                        <Link to="../login">
                           <span className="text-primary underline ">
                              {t('sign_in')}
                           </span>
                        </Link>
                     </Text>
                  </Box>
               </Box>
            </Box>
         </Box>
      </>
   )
}

export default Register
