import { useMutation, useQueryClient } from 'react-query'
import { useApiRequests } from 'API/apiRequests'
import { useRecoilState } from 'recoil'
import { CurrentCourse, CurrentText } from 'Recoil/Atoms'
import { currentLang } from 'Utils/HelperMethods'

export const useGetCourseLesson = () => {
   const { getCourseLessons } = useApiRequests()
   const [, setCurrentCourse] = useRecoilState(CurrentCourse)
   const [, setCourseText] = useRecoilState(CurrentText)

   const queryClient = useQueryClient()

   const { isLoading, mutate } = useMutation(getCourseLessons, {
      onSuccess: (data) => {
         queryClient.invalidateQueries('CourseLessons')
         setCurrentCourse(data.data.data)
         if (currentLang === 'en') {
            setCourseText({
               text: data.data.data[0][0]?.text,
               id: data.data.data[0][0]?.id
            })
         } else {
            setCourseText({
               text: data.data.data[0][0]?.arText,
               id: data.data.data[0][0]?.id
            })
         }
      }
   })
   return { mutate, isLoading }
}
