import { useApiRequests } from 'API/apiRequests'
import { ISliderCardData } from 'Models/SliderCard-type'
import { useMutation, useQueryClient } from 'react-query'
import { useRecoilState } from 'recoil'
import { FilterdData, isEmpty } from 'Recoil/Atoms'
import { uniqBy } from 'lodash'

export const useFilterByResourseType = () => {
   const [, setData] = useRecoilState(FilterdData)
   const [, setEmpty] = useRecoilState(isEmpty)

   const { getSelectResourseType } = useApiRequests()
   const FilterdArray: ISliderCardData[] = []

   const queryClient = useQueryClient()

   const { isLoading, mutate } = useMutation(getSelectResourseType, {
      onSuccess: (data) => {
         queryClient.invalidateQueries('SelectedResType')
         data.data?.data?.map((item: ISliderCardData[]) => {
            item.map((el: ISliderCardData) => {
               FilterdArray.push(el)
            })
         })
         if (FilterdArray.length === 0) {
            setEmpty(true)
            setData([])
         } else {
            setData(uniqBy(FilterdArray, 'id'))
         }
      }
   })

   return { isLoading, mutate }
}
