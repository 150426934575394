import { Box, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { ICategoriesData } from 'Models/Categories-type'
import { useTranslation } from 'react-i18next'
import { currentLang } from 'Utils/HelperMethods'
import { useRecoilState } from 'recoil'
import { FilterdData, isEmpty, SelectedData } from 'Recoil/Atoms'
import { useEffect } from 'react'
import { useChangeFilter } from 'Hooks/queries/useChangeFilter'
import { IReactQuery } from 'Models/ReactQuery-type'

interface RadioInputProps {
   data: ICategoriesData[]
   type: string
   SelectCategory: IReactQuery
   SelectType: IReactQuery
   SelectBoth: IReactQuery
}

const RadioInputGroup: React.FunctionComponent<RadioInputProps> = (props) => {
   const [, setData] = useRecoilState(FilterdData)
   const [selectedData] = useRecoilState(SelectedData)
   const [, setEmpty] = useRecoilState(isEmpty)
   const { t } = useTranslation('translation')
   const { checkSelect } = useChangeFilter()

   const hundleClick = (id: number, type: string) => {
      checkSelect(id, type, selectedData)
   }

   useEffect(() => {
      if (selectedData.Category !== 0 && selectedData.Type !== 0) {
         props.SelectBoth.mutate(selectedData)
      }
      if (selectedData.Category === 0 && selectedData.Type === 0) {
         setData([])
      }
      if (selectedData.Category !== 0 && selectedData.Type === 0) {
         props.SelectCategory.mutate(selectedData.Category)
      }
      if (selectedData.Category === 0 && selectedData.Type !== 0) {
         props.SelectType.mutate(selectedData.Type)
      }
   }, [selectedData])

   return (
      <RadioGroup
         isDisabled={
            props.SelectCategory.isLoading ||
            props.SelectType.isLoading ||
            props.SelectBoth.isLoading
         }
         colorScheme="green"
         defaultValue="0">
         <Stack>
            {props.type === 'Categories' && (
               <Box className="hover:bg-light-green">
                  <Radio
                     className="hover:bg-light-green"
                     onChange={() => {
                        hundleClick(0, props.type)
                        setEmpty(false)
                     }}
                     value="0">
                     {t('All')}
                  </Radio>
               </Box>
            )}
            {props.type === 'Type' && (
               <Box className="hover:bg-light-green">
                  <Radio
                     onChange={() => {
                        hundleClick(0, props.type)
                        setEmpty(false)
                     }}
                     className="hover:bg-light-green"
                     value="0">
                     {t('All')}
                  </Radio>
               </Box>
            )}

            {currentLang === 'en' ? (
               <>
                  {props.data.map((item) =>
                     item.title ? (
                        <Box className="hover:bg-light-green">
                           <Radio
                              _hover={{ backgroundColor: 'light-green' }}
                              key={item.id}
                              className="my-2 hover:bg-light-green"
                              onChange={() => {
                                 hundleClick(item.id, props.type)
                              }}
                              value={`${item.id}`}>
                              {item.title}
                           </Radio>
                        </Box>
                     ) : (
                        <Box className="hover:bg-light-green">
                           <Radio
                              _hover={{ backgroundColor: 'light-green' }}
                              key={item.id}
                              onChange={() => {
                                 hundleClick(item.id, props.type)
                              }}
                              className="my-2 hover:bg-light-green"
                              value={`${item.id}`}>
                              {item.type}
                           </Radio>
                        </Box>
                     )
                  )}
               </>
            ) : (
               <>
                  {props.data.map((item) =>
                     item.title ? (
                        <Box className="hover:bg-light-green">
                           <Radio
                              className="my-2 hover:bg-light-green"
                              onChange={() => {
                                 hundleClick(item.id, props.type)
                              }}
                              value={`${item.id}`}>
                              {item.arTitle}
                           </Radio>
                        </Box>
                     ) : (
                        <Box className="hover:bg-light-green">
                           <Radio
                              onChange={() => {
                                 hundleClick(item.id, props.type)
                              }}
                              className="my-2 hover:bg-light-green   "
                              value={`${item.id}`}>
                              {item.arType}
                           </Radio>
                        </Box>
                     )
                  )}
               </>
            )}
         </Stack>
      </RadioGroup>
   )
}

export default RadioInputGroup
