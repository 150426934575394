import {
   Box,
   Button,
   Container,
   Flex,
   Heading,
   Input,
   List,
   ListItem,
   Select,
   SimpleGrid,
   Stack,
   StackDivider,
   Text,
   useColorModeValue,
   VStack
} from '@chakra-ui/react'
import SectionsAccordion from 'Components/Accordion/SectionsAccordion'
import EditableCard from 'Components/Cards/EditableCard'
import InputEditable from 'Components/Inputs/InputEditable'
import AddSectionPanel from 'Components/Panels/AdminPanel/AddSectionPanel'
import TextSketeton from 'Components/Skeleton/TextSketeton'
import { useUpdateCourse } from 'Hooks/queries/Admin/Courses/useUpdateCourse'
import { useAddSection } from 'Hooks/queries/Admin/Sections/useAddSection'
import { useGetSections } from 'Hooks/queries/Admin/Sections/useGetSections'
import useFillFormData from 'Hooks/useFillFormData'
import useSetRenderData from 'Hooks/useSetRenderData'
import useUpdateEditeCourse from 'Hooks/useUpdateEditeCourse'
import { ISliderCardData } from 'Models/SliderCard-type'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import {
   CurrentEditCourse,
   CurrentSections,
   CurrentUpdatedImage
} from 'Recoil/Atoms'

interface AdminCourseDetailsProps {
   data: ISliderCardData
}

const AdminCourseDetails: React.FunctionComponent<AdminCourseDetailsProps> = (
   props
) => {
   const [currentEditable] = useRecoilState(CurrentEditCourse)
   const { setDefualtData } = useSetRenderData()
   const [, setCurrentImage] = useRecoilState(CurrentUpdatedImage)
   const { mutate, isLoading } = useGetSections()
   const { doUpdateCourse } = useUpdateEditeCourse()
   const [sections] = useRecoilState(CurrentSections)
   const { doFillCourse } = useFillFormData()
   const updateCourse = useUpdateCourse()
   const Sections = useAddSection()

   useEffect(() => {
      setDefualtData(props.data)
      mutate(props.data.id)
   }, [])

   const hundleSave = () => {
      const formData = doFillCourse(currentEditable)
      const mutateData = {
         data: formData,
         id: props.data.id
      }
      updateCourse.mutate(mutateData)
   }

   return (
      <>
         <Container maxW={'8xl'}>
            <SimpleGrid
               columns={{ base: 1, lg: 2 }}
               spacing={{ base: 8, md: 10 }}
               py={{ base: 18, md: 24 }}>
               <Flex gap="20" direction="column">
                  <Input
                     w="100%"
                     h="100%"
                     onChange={(e) => {
                        setCurrentImage(e.currentTarget.files?.[0])
                     }}
                     backgroundRepeat="no-repeat"
                     backgroundImage={`url("${props.data.imgThumbnail}")`}
                     type="file"></Input>
                  <Box>
                     <Text className="text-3xl font-semibold pb-8">
                        Sections
                     </Text>
                     <Box>
                        <Text
                           fontSize={{ base: '16px', lg: '18px' }}
                           color="primary"
                           fontWeight={'500'}
                           textTransform={'uppercase'}
                           mb={'4'}>
                           Details
                        </Text>

                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                           <List spacing={2}>
                              <ListItem>Downloadable</ListItem>
                              <ListItem>Draft</ListItem>
                              <ListItem>Hours</ListItem>
                           </List>
                           <List spacing={2}>
                              <ListItem>
                                 <InputEditable
                                    doUpdate={doUpdateCourse}
                                    title="Number of Downloadable"
                                    text={props.data.downloadable.toString()}
                                 />
                              </ListItem>
                              <ListItem>
                                 <Select
                                    onSelect={(e) => {
                                       doUpdateCourse(
                                          'draft',
                                          e.currentTarget.value
                                       )
                                    }}
                                    defaultValue={props.data.draft}>
                                    <option value={0}>Unpublish</option>
                                    <option value={1}>Publish</option>
                                 </Select>
                              </ListItem>
                              <ListItem>
                                 <InputEditable
                                    doUpdate={doUpdateCourse}
                                    title="Number of Hours"
                                    text={props.data.hours.toString()}
                                 />
                              </ListItem>
                           </List>
                        </SimpleGrid>
                     </Box>
                  </Box>
               </Flex>
               <Stack spacing={{ base: 6, md: 10 }}>
                  <Box as={'header'}>
                     <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}>
                        <InputEditable
                           doUpdate={doUpdateCourse}
                           title="Title"
                           text={props.data.title}
                        />
                     </Heading>
                     <Text
                        color={useColorModeValue('gray.900', 'gray.400')}
                        fontWeight={300}
                        fontSize={'2xl'}>
                        <InputEditable
                           doUpdate={doUpdateCourse}
                           title="Arabic Title"
                           text={props.data.arTitle}
                        />
                     </Text>
                     <Text
                        fontSize={{ base: '16px', lg: '18px' }}
                        color="primary"
                        fontWeight={'500'}
                        mb={'4'}>
                        <InputEditable
                           doUpdate={doUpdateCourse}
                           title="LeadText"
                           text={props.data.leadText}
                        />
                     </Text>
                     <Text
                        fontSize={{ base: '16px', lg: '18px' }}
                        color="primary"
                        fontWeight={'500'}
                        mb={'4'}>
                        <InputEditable
                           doUpdate={doUpdateCourse}
                           title="Arabic LeadText"
                           text={props.data.arLeadText}
                        />
                     </Text>
                  </Box>
                  <Stack
                     spacing={{ base: 4, sm: 6 }}
                     direction={'column'}
                     divider={
                        <StackDivider
                           borderColor={useColorModeValue(
                              'gray.200',
                              'gray.600'
                           )}
                        />
                     }>
                     <VStack spacing={{ base: 4, sm: 6 }}>
                        <EditableCard
                           title="Description"
                           description={currentEditable.Description}
                        />
                        <EditableCard
                           title="Arabic Description"
                           description={currentEditable['Arabic Description']}
                        />
                     </VStack>
                  </Stack>
               </Stack>
            </SimpleGrid>
            <Box className="w-full py-6">
               <Button
                  rounded={'none'}
                  w={'full'}
                  mt={8}
                  size={'lg'}
                  onClick={() => {
                     hundleSave()
                  }}
                  py={'7'}
                  isLoading={updateCourse.isLoading}
                  bg={'primary'}
                  color={useColorModeValue('white', 'gray.900')}
                  textTransform={'uppercase'}
                  _hover={{
                     transform: 'translateY(2px)',
                     boxShadow: 'lg'
                  }}>
                  Save Data
               </Button>
               <Box className="py-8">
                  <Text className="text-3xl font-semibold py-4">
                     Setions & Lessons
                  </Text>
                  <Box className="py-4">
                     <AddSectionPanel
                        button="Add"
                        modalTitle="Add"
                        mutate={Sections}
                        id={props.data.id}
                     />
                  </Box>
                  {isLoading ? (
                     <>
                        <TextSketeton times={6} />
                     </>
                  ) : (
                     <>
                        {sections?.map((item) => (
                           <SectionsAccordion sections={item} />
                        ))}
                     </>
                  )}
               </Box>
            </Box>
         </Container>
      </>
   )
}

export default AdminCourseDetails
