import { Box, Skeleton, Stack, Text } from '@chakra-ui/react'
import EditProfileForm from 'Components/Forms/EditProfileForm'
import { useGetUserData } from 'Hooks/queries/useGetUserData'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { UserData } from 'Recoil/Atoms'

interface EditProfileProps {}

const EditProfile: React.FunctionComponent<EditProfileProps> = () => {
   const { t } = useTranslation('translation')
   const { isLoading } = useGetUserData()
   const [dataUser] = useRecoilState(UserData)

   return (
      <>
         <Box className="p-24 lg:p-2">
            <Text className="text-primary text-xl py-6">
               {t('edit_profile')}
            </Text>
            {isLoading ? (
               <Stack gap="7" className="w-1/2">
                  <Skeleton height="20px" />
                  <Skeleton height="20px" />
                  <Skeleton height="20px" />
                  <Skeleton height="20px" />
                  <Skeleton height="20px" />
                  <Skeleton height="20px" />
               </Stack>
            ) : (
               <EditProfileForm data={dataUser} />
            )}
         </Box>
      </>
   )
}

export default EditProfile
