import { Box, Button } from '@chakra-ui/react'
import { currentLang } from 'Utils/HelperMethods'
import useEnrolledCourse from 'Hooks/useChangeText'
import { BiRightArrowAlt } from 'react-icons/bi'
import { BiLeftArrowAlt } from 'react-icons/bi'
import { useRecoilState } from 'recoil'
import { CurrentCourse } from 'Recoil/Atoms'
import { useTranslation } from 'react-i18next'

interface NextPrevProps {
   currentPageIndex: number
   currectSectionIndex: number
}

const NextPrev: React.FunctionComponent<NextPrevProps> = (props) => {
   const { t } = useTranslation('translation')
   const { handleNextClick, handlePrevClick } = useEnrolledCourse()
   const [courseLesson] = useRecoilState(CurrentCourse)

   return (
      <>
         {currentLang === 'ar' ? (
            <Box className="flex w-full justify-between py-6">
               <Box>
                  {props.currentPageIndex === 0 &&
                  props.currectSectionIndex === 0 ? (
                     <></>
                  ) : (
                     <Button
                        className="ar-header next-btn-color"
                        onClick={() => {
                           {
                              handlePrevClick(courseLesson)
                           }
                        }}>
                        <BiRightArrowAlt
                           style={{ fontSize: '2.5rem', color: '#7AA32D' }}
                        />
                        {t('Previous')}
                     </Button>
                  )}
               </Box>
               <Box>
                  {props.currentPageIndex ===
                     courseLesson[props.currectSectionIndex]?.length - 1 &&
                  props.currectSectionIndex === courseLesson.length - 1 ? (
                     <></>
                  ) : (
                     <Button
                        onClick={() => {
                           {
                              handleNextClick(courseLesson)
                           }
                        }}>
                        {t('next')}

                        <BiLeftArrowAlt
                           color="#7AA32D"
                           style={{ fontSize: '2.5rem', color: '#7AA32D' }}
                        />
                     </Button>
                  )}
               </Box>
            </Box>
         ) : (
            <Box className="flex w-full justify-between py-6">
               <Box>
                  {props.currentPageIndex === 0 &&
                  props.currectSectionIndex === 0 ? (
                     <></>
                  ) : (
                     <Button
                        onClick={() => {
                           {
                              handlePrevClick(courseLesson)
                           }
                        }}>
                        <BiLeftArrowAlt
                           style={{ fontSize: '2.5rem', color: '#7AA32D' }}
                        />
                        {t('Previous')}
                     </Button>
                  )}
               </Box>
               <Box>
                  {props.currentPageIndex ===
                     courseLesson[props.currectSectionIndex]?.length - 1 &&
                  props.currectSectionIndex === courseLesson.length - 1 ? (
                     <></>
                  ) : (
                     <Button
                        onClick={() => {
                           {
                              handleNextClick(courseLesson)
                           }
                        }}>
                        {t('next')}
                        <BiRightArrowAlt
                           style={{ fontSize: '2.5rem', color: '#7AA32D' }}
                        />
                     </Button>
                  )}
               </Box>
            </Box>
         )}
      </>
   )
}

export default NextPrev
