export const MenuItems = [
   {
      title: 'Categories',
      buttons: [
         {
            title: 'Category List',
            link: 'category-list'
         },
         {
            title: 'Add Category',
            link: 'category-add'
         }
      ]
   },
   {
      title: 'Courses',
      buttons: [
         {
            title: 'Courses List',
            link: 'courses-list'
         },
         {
            title: 'Add Course',
            link: 'courses-add'
         }
      ]
   },
   {
      title: 'Lesson Plans',
      buttons: [
         {
            title: 'Lesson Plans List',
            link: 'lessonPlan-list'
         },
         {
            title: 'Add Lesson Plan',
            link: 'lessonPlan-add'
         }
      ]
   },
   {
      title: 'Videos',
      buttons: [
         {
            title: 'Videos List',
            link: 'video-list'
         },
         {
            title: 'Add Video',
            link: 'video-add'
         }
      ]
   },
   {
      title: 'Users',
      buttons: [
         {
            title: 'User List',
            link: 'user-list'
         }
      ]
   }
]
