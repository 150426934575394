import { Box, Heading, Text, Button, Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export default function NotFound() {
   return (
      <Box
         textAlign="center"
         className="h-full flex flex-col items-center bg-[#f7f7f7]"
         py={10}
         px={6}>
         <Heading
            display="inline-block"
            as="h2"
            size="4xl"
            bg="primary"
            backgroundClip="text">
            404 Error
         </Heading>
         <Text fontSize="18px" mt={3} mb={2}>
            Page Not Found
         </Text>
         <Text color={'gray.500'} mb={6}>
            The page you're looking for does not seem to exist
         </Text>
         <Image className="py-4 w-96" src="/Images/404.jpeg" />
         <Link to="/">
            <Button
               bg="primary"
               color="white"
               variant="solid">
               Go to Home
            </Button>
         </Link>
      </Box>
   )
}
