import { Box, Button, Input, Select, Text } from '@chakra-ui/react'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TinyEditorAdd from 'Components/Inputs/TinyEditorAdd'
import { AddVideoSchema } from 'Configs/formsSchemas'
import { ICategoriesData } from 'Models/Categories-type'
import { IVideos } from 'Models/Videos-type'
import { getimgThumbnail, getVideo } from 'Utils/VideoPrfix'
import { useAddVideo } from 'Hooks/queries/Admin/Videos/useAddVideo'
interface AddVideoFormProps {
   data?: IVideos
   category: ICategoriesData[]
   level: number[]
}

const AddVideoForm: React.FunctionComponent<AddVideoFormProps> = (props) => {
   const { t } = useTranslation('translation')
   const [enText] = useState(props.data?.description)
   const [arText] = useState(props.data?.arDescription)
   const { mutate, isLoading } = useAddVideo()

   return (
      <Formik
         initialValues={{
            title: props.data?.title,
            arTitle: props.data?.arTitle,
            leadText: props.data?.leadText,
            arLeadText: props.data?.arLeadText,
            imgThumbnail: props.data?.imgThumbnail,
            VideoURL: props.data?.VideoURL,
            draft: props.data?.draft,
            category_id: props.data?.category_id,
            level_id: props.data?.level_id
         }}
         onSubmit={(e) => {
            mutate(e)
         }}
         validationSchema={AddVideoSchema}>
         {({ handleChange, handleSubmit, values, setFieldValue }) => {
            const hundleFillImages = (image: string, video: string) => {
               setFieldValue('imgThumbnail', image)
               setFieldValue('VideoURL', video)
            }

            return (
               <Form className="w-3/5 lg:w-4/5" onSubmit={handleSubmit}>
                  <Box className="py-4">
                     <Text className="text-2xl text-primary py-4 font-semibold">
                        Title
                     </Text>
                     <Input
                        type="text"
                        name="title"
                        className="font-medium py-4"
                        variant="filled"
                        onChange={handleChange}
                        value={values.title}
                     />
                     <ErrorMessage
                        render={(error) => (
                           <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                              {t(error)}
                           </Box>
                        )}
                        name="email"
                        component="div"
                     />
                  </Box>
                  <Box className="py-4">
                     <Text className="text-2xl text-primary py-4 font-semibold">
                        Arabic Title
                     </Text>
                     <Input
                        type="text"
                        name="arTitle"
                        className="font-medium py-4"
                        variant="filled"
                        onChange={handleChange}
                        value={values.arTitle}
                     />
                     <ErrorMessage
                        render={(error) => (
                           <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                              {t(error)}
                           </Box>
                        )}
                        name="email"
                        component="div"
                     />
                  </Box>
                  <Box className="py-4">
                     <Text className="text-2xl text-primary py-4 font-semibold">
                        Lead Text
                     </Text>
                     <Input
                        type="text"
                        name="leadText"
                        className="font-medium py-4"
                        variant="filled"
                        onChange={handleChange}
                        value={values.leadText}
                     />
                     <ErrorMessage
                        render={(error) => (
                           <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                              {t(error)}
                           </Box>
                        )}
                        name="email"
                        component="div"
                     />
                  </Box>
                  <Box className="py-4">
                     <Text className="text-2xl text-primary py-4 font-semibold">
                        Arabic Lead Text
                     </Text>
                     <Input
                        type="text"
                        name="arLeadText"
                        className="font-medium py-4"
                        variant="filled"
                        onChange={handleChange}
                        value={values.arLeadText}
                     />
                     <ErrorMessage
                        render={(error) => (
                           <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                              {t(error)}
                           </Box>
                        )}
                        name="email"
                        component="div"
                     />
                  </Box>
                  <Box className="py-4">
                     <Text className="text-2xl text-primary py-4 font-semibold">
                        Description
                     </Text>
                     <TinyEditorAdd
                        initValue={enText}
                        setEditable={(value) =>
                           setFieldValue('description', value)
                        }
                     />
                     <ErrorMessage
                        render={(error) => (
                           <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                              {t(error)}
                           </Box>
                        )}
                        name="email"
                        component="div"
                     />
                  </Box>
                  <Box className="py-4">
                     <Text className="text-2xl text-primary py-4 font-semibold">
                        Arabic Description
                     </Text>
                     <TinyEditorAdd
                        initValue={arText}
                        setEditable={(value) =>
                           setFieldValue('arDescription', value)
                        }
                     />
                     <ErrorMessage
                        render={(error) => (
                           <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                              {t(error)}
                           </Box>
                        )}
                        name="email"
                        component="div"
                     />
                  </Box>

                  <Box className="py-4">
                     <Text className="text-2xl text-primary py-4 font-semibold">
                        Vidoe Prifx
                     </Text>
                     <Input
                        type="text"
                        name="age"
                        className="font-medium py-4"
                        variant="filled"
                        onChange={(e) => {
                           hundleFillImages(
                              getimgThumbnail(e.target.value),
                              getVideo(e.target.value)
                           )
                        }}
                     />
                     <ErrorMessage
                        render={(error) => (
                           <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                              {t(error)}
                           </Box>
                        )}
                        name="email"
                        component="div"
                     />
                  </Box>
                  <Box className="py-4">
                     <Text className="text-2xl text-primary py-4 font-semibold">
                        Status
                     </Text>
                     <Select
                        name="draft"
                        onChange={handleChange}
                        placeholder="Select option">
                        <option value={0}>Unpublish</option>
                        <option value={0}>Publish</option>
                     </Select>
                     <ErrorMessage
                        render={(error) => (
                           <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                              {t(error)}
                           </Box>
                        )}
                        name="email"
                        component="div"
                     />
                  </Box>
                  <Box className="py-4">
                     <Text className="text-2xl text-primary py-4 font-semibold">
                        Category
                     </Text>
                     <Select
                        name="category_id"
                        onChange={handleChange}
                        placeholder="Select option">
                        {props.category?.map((item) => (
                           <option value={item.id}>{item.title}</option>
                        ))}
                     </Select>
                     <ErrorMessage
                        render={(error) => (
                           <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                              {t(error)}
                           </Box>
                        )}
                        name="email"
                        component="div"
                     />
                  </Box>
                  <Box className="py-4">
                     <Text className="text-2xl text-primary py- font-semibold">
                        Level
                     </Text>
                     <Select
                        name="level_id"
                        onChange={handleChange}
                        placeholder="Select option">
                        {props.level.map((item) => (
                           <option value={item}>{item}</option>
                        ))}
                     </Select>
                     <ErrorMessage
                        render={(error) => (
                           <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                              {t(error)}
                           </Box>
                        )}
                        name="level_id"
                        component="div"
                     />
                  </Box>
                  <Box className="flex justify-between pt-12">
                     <Button
                        justifyContent="center"
                        variant="primary"
                        isLoading={isLoading}
                        type="submit">
                        Add Course
                     </Button>
                  </Box>
               </Form>
            )
         }}
      </Formik>
   )
}

export default AddVideoForm
