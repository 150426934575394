import { Button, Text, useColorModeValue } from '@chakra-ui/react'
import TinyEditor from 'Components/Inputs/TinyEditor'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { CurrentEditCourse } from 'Recoil/Atoms'

interface EditableCardProps {
   description: string
   title: string
}

const EditableCard: React.FunctionComponent<EditableCardProps> = (props) => {
   const [editable, setEditable] = useState(false)
   const [currentEditable] = useRecoilState(CurrentEditCourse)
   
   return (
      <>
         {editable ? (
            <>
               <TinyEditor
                  initValue={props.description}
                  title={props.title}
                  setEditable={setEditable}
               />
            </>
         ) : (
            <>
               <Text
                  color={useColorModeValue('gray.500', 'gray.400')}
                  fontSize={'2xl'}
                  fontWeight={'300'}>
                  <Text
                     dangerouslySetInnerHTML={{
                        __html: props.description
                     }}></Text>
               </Text>
               <Button
                  onClick={() => {
                     setEditable(true)
                  }}>
                  Edit
               </Button>
            </>
         )}
      </>
   )
}

export default EditableCard
