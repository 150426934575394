import { Box, Button, Link, Text } from '@chakra-ui/react'
import DescriptionCard from 'Components/Cards/DescriptionCard'
import { ILessonPlan } from 'Models/LessonPlan-type'
import { useTranslation } from 'react-i18next'
import { currentLang, isLogin } from 'Utils/HelperMethods'

interface LessonPlanDetailsProps {
   data: ILessonPlan
}

const LessonPlanDetails: React.FunctionComponent<LessonPlanDetailsProps> = (
   props
) => {
   const { t } = useTranslation('translation')
   return (
      <>
         <Box className="bg-light-blue pt-8 max-h-[23rem] lg:max-h-full lg:items-start lg:py-8 px-12 lg:px-0 flex w-full lg:flex-col">
            <Box className="py-12 w-1/2 lg:w-full lg:px-8 flex flex-col gap-y-5 items-start lg:items-start">
               {currentLang === 'en' ? (
                  <>
                     <Button cursor="default" variant="LessonPlans">
                        {props.data.type}
                     </Button>
                     <Text className="text-2xl text-primary font-normal">
                        {props.data.title}
                     </Text>
                     <Text className="text-lg text-white font-light">
                        {props.data.leadText}
                     </Text>
                  </>
               ) : (
                  <>
                     <Button cursor="default" variant="LessonPlans">
                        {props.data.arType}
                     </Button>
                     <Text className="text-2xl text-primary font-normal">
                        {props.data.arTitle}
                     </Text>
                     <Text className="text-lg text-white font-light">
                        {props.data.arLeadText}
                     </Text>
                  </>
               )}
            </Box>
         </Box>
         <Box className="w-4/6 p-8 lg:p-2 lg:w-full min-h-[61vh]">
            <Box className="rounded-lg shadow-lg border bg-white p-12">
               {currentLang === 'en' ? (
                  <>
                     <DescriptionCard description={props.data.description} />
                     {isLogin !== undefined ? (
                        <Link target="_blank" href={props.data.LP_URL}>
                           <Button
                              size="lg"
                              w={{ base: '100%', lg: '25%' }}
                              marginTop="10"
                              variant="LessonPlans">
                              {t('view_now')}
                           </Button>
                        </Link>
                     ) : (
                        <Link href="../login">
                           <Button
                              size="lg"
                              w={{ base: '100%', lg: '25%' }}
                              marginTop="10"
                              variant="LessonPlans">
                              {t('view_now')}
                           </Button>
                        </Link>
                     )}
                  </>
               ) : (
                  <>
                     <DescriptionCard description={props.data.arDescription} />
                     {isLogin !== undefined ? (
                        <Link target="_blank" href={props.data.arLP_URL}>
                           <Button
                              size="lg"
                              w={{ base: '100%', lg: '25%' }}
                              marginTop="10"
                              variant="LessonPlans">
                              {t('view_now')}
                           </Button>
                        </Link>
                     ) : (
                        <Link href="../login">
                           <Button
                              size="lg"
                              w={{ base: '100%', lg: '25%' }}
                              marginTop="10"
                              variant="LessonPlans">
                              {t('view_now')}
                           </Button>
                        </Link>
                     )}
                  </>
               )}
            </Box>
         </Box>
      </>
   )
}

export default LessonPlanDetails
