import { useGetAllVideos } from 'Hooks/queries/useGetAllVideos'
import { ILessonPlan } from 'Models/LessonPlan-type'
import { ISliderCardData } from 'Models/SliderCard-type'
import { IVideos } from 'Models/Videos-type'
import CourseDetails from 'Pages/CourseDetails/CourseDetails'
import EditProfile from 'Pages/EditProfile/EditProfile'
import ForgetPassword from 'Pages/ForgetPassword/ForgetPassword'
import HomePage from 'Pages/HomePage/HomePage'
import LessonPlanDetails from 'Pages/LessonPlanDetails/LessonPlanDetails'
import Login from 'Pages/Login/Login'
import MyCourses from 'Pages/MyCourses/MyCourses'
import PlayingCourse from 'Pages/PlayingCourse/PlayingCourse'
import Register from 'Pages/Register/Register'
import VideoDetails from 'Pages/VideoDetails/VideoDetails'
import { Route, Routes } from 'react-router-dom'
import Admin from 'Pages/Admin/Admin'
import AdminCourseDetails from 'Pages/Admin/CourseDetails/AdminCourseDatails'
import AdminLessonPlansDatails from 'Pages/Admin/LessonPlansDetails/AdminLessonPlansDatails'
import AdminVideoDeails from 'Pages/Admin/VideoDetails/AdminVideoDeails'
import NotFound from 'Pages/404/NotFound'
import { useGetAllCoursesAdmin } from 'Hooks/queries/Admin/Courses/useGetAllCoursesAdmin'
import { useGetLessonPlansAdmin } from 'Hooks/queries/Admin/LessonPlans/useGetLessonPlansAdmin'
import { Box, Spinner } from '@chakra-ui/react'
interface AdminRoutesProps {}

const AdminRoutes: React.FunctionComponent<AdminRoutesProps> = () => {
   const CoursesList = useGetAllCoursesAdmin()
   const LessonPlans = useGetLessonPlansAdmin()
   const Videos = useGetAllVideos()
   return (
      <>
         {CoursesList.isLoading ? (
            <Box className="pt-16 w-full flex justify-center">
               <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="primary"
                  size="xl"
               />
            </Box>
         ) : (
            <Routes>
               <Route
                  path="/resetpassword"
                  element={<ForgetPassword />}></Route>
               <Route path="/" element={<HomePage />}></Route>
               <Route path="/editprofile" element={<EditProfile />}></Route>
               <Route path="/login" element={<Login />}></Route>
               <Route path="/my-courses" element={<MyCourses />}></Route>
               <Route path="/register" element={<Register />}></Route>
               <Route path="/admin" element={<Admin />}></Route>
               {LessonPlans?.LessonPlans?.map((item: ILessonPlan) => (
                  <Route
                     path={`/admin/${item.link}`}
                     element={<AdminLessonPlansDatails data={item} />}></Route>
               ))}
               {CoursesList?.Courses?.map((item: ISliderCardData) => (
                  <Route
                     path={`/admin/${item.link}`}
                     element={<AdminCourseDetails data={item} />}></Route>
               ))}
               <Route path="/admin" element={<Admin />}></Route>
               {CoursesList?.Courses?.map((item: ISliderCardData) => (
                  <Route
                     path={`/${item.link}`}
                     element={<CourseDetails data={item} />}></Route>
               ))}
               {LessonPlans?.LessonPlans?.map((item: ILessonPlan) => (
                  <Route
                     path={`/${item.link}`}
                     element={<LessonPlanDetails data={item} />}></Route>
               ))}
               {Videos?.AllVideos?.map((item: IVideos) => (
                  <Route
                     path={`/${item.link}`}
                     element={<VideoDetails data={item} />}></Route>
               ))}
               {Videos?.AllVideos?.map((item: IVideos) => (
                  <Route
                     path={`/admin/${item.link}`}
                     element={<AdminVideoDeails data={item} />}></Route>
               ))}
               {CoursesList?.Courses?.map((item: ISliderCardData) => (
                  <Route
                     path={`/${item.link}`}
                     element={<CourseDetails data={item} />}></Route>
               ))}
               {CoursesList?.Courses?.map((item: ISliderCardData) => (
                  <Route
                     path={`/playing/${item.link}`}
                     element={<PlayingCourse data={item} />}></Route>
               ))}
               <Route path="*" element={<NotFound />} />
            </Routes>
         )}
      </>
   )
}

export default AdminRoutes
