import { Editable, EditableInput, EditablePreview } from '@chakra-ui/react'
import { useEffect } from 'react'
import { getimgThumbnail, getVideo } from 'Utils/VideoPrfix'

interface VideoInputEditableProps {
   text: string
   title: string
   doUpdate: (e: string, text: string) => void
}

const VideoInputEditable: React.FunctionComponent<VideoInputEditableProps> = (
   props
) => {
   useEffect(() => {
      props.doUpdate(props.title, props.text)
   }, [props.title])

   return (
      <>
         <Editable
            onSubmit={(e) => {
               props.doUpdate('imgThumbnail', getimgThumbnail(e))
               props.doUpdate('VideoID', getVideo(e))
            }}
            textAlign="center"
            defaultValue={props.text}>
            <EditablePreview />
            <EditableInput />
         </Editable>
      </>
   )
}

export default VideoInputEditable
