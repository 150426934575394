import {
   Modal,
   ModalContent,
   ModalFooter,
   ModalBody,
   useDisclosure,
   Button
} from '@chakra-ui/react'
import { ReactNode } from 'react'

interface LessonModalProps {
   children: ReactNode
   title: string
}

const AddModal: React.FunctionComponent<LessonModalProps> = ({
   children,
   title
}) => {
   const { isOpen, onOpen, onClose } = useDisclosure()

   return (
      <>
         <Button color="Highlight" onClick={onOpen}>
            {title}
         </Button>
         <Modal
            variant="solid"
            trapFocus={false}
            size="full"
            isOpen={isOpen}
            onClose={onClose}>
            <ModalContent>
               <ModalBody>{children}</ModalBody>
               <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                     Close
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   )
}

export default AddModal
