import { Box, Button } from '@chakra-ui/react'
import SearchInput from 'Components/Inputs/SearchInput'
import MobileDrawer from 'Components/Menu/MobileDrawer'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { currentLang, handleChangeLang } from 'Utils/HelperMethods'
interface NavbarProps {}

const Navbar: React.FunctionComponent<NavbarProps> = () => {
   const location = useLocation()
   const { t } = useTranslation('translation')
   return (
      <>
         <Box className="flex z-50 items-center justify-between gap-x-8 py-3 px-8 shadow-xl sticky">
            <Box className="flex items-center gap-x-6 lg:gap-x-1 justify-center">
               {currentLang === 'en' ? (
                  <>
                     <img
                        src="/Images\horiziontal-thaki-logo-white-bg-web.png"
                        className="h-12 cursor-pointer"
                        alt="Logo"
                        onClick={() => {
                           window.location.replace('/')
                        }}
                     />
                  </>
               ) : (
                  <>
                     <img
                        src="/Images\TDT-logo-Arabic.png"
                        className="h-12 cursor-pointer"
                        alt="Logo"
                        onClick={() => {
                           window.location.replace('/')
                        }}
                     />
                  </>
               )}
               <Box className="lg:hidden w-full">
                  <Link to="../">
                     <Button variant="unstyled">{t('Explore')}</Button>
                  </Link>
               </Box>
            </Box>
            {location.pathname === '/' && <SearchInput />}

            <Box className="flex">
               <Box className="flex gap-x-2 justify-between lg:justify-end">
                  <Box className="lg:hidden">
                     <Link to="../Login">
                        <Button className="border" variant="ghost">
                           {t('sign_in')}
                        </Button>
                     </Link>
                  </Box>
                  <Link to="../register">
                     <Button
                        w={{ base: '4.5rem', lg: '100%' }}
                        variant="primary">
                        {t('register')}
                     </Button>
                  </Link>
               </Box>
               <Box className="flex gap-x-2 justify-between lg:hidden">
                  <Button
                     style={{ fontFamily: 'Montserrat' }}
                     className="border-r"
                     onClick={() => {
                        handleChangeLang('en')
                     }}
                     variant="blank">
                     En
                  </Button>
                  <Button
                     style={{ fontFamily: 'NotoKufiArabic' }}
                     onClick={() => {
                        handleChangeLang('ar')
                     }}
                     variant="blank">
                     عربي
                  </Button>
               </Box>
               <MobileDrawer />
            </Box>
         </Box>
      </>
   )
}

export default Navbar
