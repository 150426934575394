import { Box } from '@chakra-ui/react'
import AdminList from 'Components/Accordion/AdminList'
import CourseAdminCard from 'Components/Cards/AdminCards/CourseAdminCard'
import AddCourseForm from 'Components/Forms/AddCourseForm'
import AddLessonPlanForm from 'Components/Forms/AddLessonPlanForm'
import AddVideoForm from 'Components/Forms/AddVideoForm'
import SearchInput from 'Components/Inputs/SearchInput'
import CategoryPanel from 'Components/Panels/AdminPanel/CategoryPanel'
import CoursesPanel from 'Components/Panels/AdminPanel/CoursesPanel'
import LessonPlanPanel from 'Components/Panels/AdminPanel/LessonPlanPanel'
import Statistics from 'Components/Panels/AdminPanel/Statistics'
import UsersTable from 'Components/Table/UsersTable'
import { useGetAllCoursesAdmin } from 'Hooks/queries/Admin/Courses/useGetAllCoursesAdmin'
import { useGetLessonPlansAdmin } from 'Hooks/queries/Admin/LessonPlans/useGetLessonPlansAdmin'
import { useGetAllUsers } from 'Hooks/queries/Admin/useGetAllUsers'
import { useGetAllVideos } from 'Hooks/queries/useGetAllVideos'
import { useGetCategories } from 'Hooks/queries/useGetCategories'
import { MenuItems } from 'Mock/AdminButtons'
import { IMenuAdmin } from 'Models/AdminButtons-type'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { CurrentAdminView, FilterdData } from 'Recoil/Atoms'

interface AdminProps {}

const Admin: React.FunctionComponent<AdminProps> = () => {
   const Categories = useGetCategories()
   const Videos = useGetAllVideos()
   const UserLists = useGetAllUsers()
   const LessonPlans = useGetLessonPlansAdmin()
   const CoursesList = useGetAllCoursesAdmin()
   const [FilteredData] = useRecoilState(FilterdData)
   const [currentView] = useRecoilState(CurrentAdminView)

   useEffect(() => {
      if (currentView === 'courses-list') {
         CoursesList.attributes.refetch()
      }
      if (currentView === 'lessonPlan-list') {
         LessonPlans.attributes.refetch()
      }
      if (currentView === 'video-list') {
         Videos.attributes.refetch()
      }
   }, [currentView])

   return (
      <>
         <Box className="flex min-h-screen ">
            <Box className="flex-col w-1/4 border-x">
               {MenuItems.map((item: IMenuAdmin) => (
                  <AdminList title={item.title} buttons={item.buttons} />
               ))}
            </Box>
            <Box className="w-3/4 p-12">
               <Box className="w-full flex flex-col justify-center items-center">
                  <SearchInput />
               </Box>
               {currentView === '' && (
                  <Statistics
                     category={Categories.Categories?.length}
                     courses={CoursesList.Courses?.length}
                     lessons={LessonPlans.LessonPlans?.length}
                     videos={Videos.AllVideos?.length}
                     users={UserLists.users?.length}
                  />
               )}
               {FilteredData.length !== 0 ? (
                  <>
                     <Box className="px-2 flex bg-dark-gray flex-wrap">
                        {FilteredData.map((item) => (
                           <CourseAdminCard data={item} />
                        ))}
                     </Box>
                  </>
               ) : (
                  <>
                     {currentView === 'courses-list' && (
                        <CoursesPanel CoursesList={CoursesList.Courses} />
                     )}
                     {currentView === 'lessonPlan-list' && (
                        <LessonPlanPanel
                           LessonsPlansList={LessonPlans.LessonPlans}
                        />
                     )}
                     {currentView === 'video-list' && (
                        <LessonPlanPanel
                           LessonsPlansList={Videos.AllVideos}
                           type="video"
                        />
                     )}
                     {currentView === 'category-list' && <CategoryPanel />}
                  </>
               )}
               {currentView === 'courses-add' && (
                  <Box>
                     <AddCourseForm
                        category={Categories.Categories}
                        level={[1, 2, 3]}
                     />
                  </Box>
               )}
               {currentView === 'lessonPlan-add' && (
                  <Box>
                     <AddLessonPlanForm
                        category={Categories.Categories}
                        level={[1, 2, 3]}
                     />
                  </Box>
               )}
               {currentView === 'video-add' && (
                  <Box>
                     <AddVideoForm
                        category={Categories.Categories}
                        level={[1, 2, 3]}
                     />
                  </Box>
               )}
               {currentView === 'user-list' && (
                  <Box>
                     <UsersTable />
                  </Box>
               )}
            </Box>
         </Box>
      </>
   )
}

export default Admin
