import { useToast } from '@chakra-ui/react'
import { useApiRequests } from 'API/apiRequests'
import { useMutation, useQueryClient } from 'react-query'

export const useAddLesson = () => {
   const { addLesson } = useApiRequests()
   const toast = useToast()
   const queryClient = useQueryClient()

   const { isLoading, mutate } = useMutation(addLesson, {
      onError: () => {
         queryClient.invalidateQueries('addedLesosn')
         toast({
            title: 'Something went Wrong',
            status: 'error',
            duration: 2000,
            position: 'top',
            isClosable: true
         })
      },
      onSuccess: () => {
         queryClient.invalidateQueries('addedLesosn')
         toast({
            title: 'Lesson Added Successfully',
            status: 'success',
            duration: 2000,
            position: 'top',
            isClosable: true
         })
      }
   })

   return { mutate, isLoading }
}
