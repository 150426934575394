import { Box, Text } from '@chakra-ui/react'
import Slider from 'Components/Slider/Slider'
import { ISliderCardData } from 'Models/SliderCard-type'

interface SliderBoxProps {
   title: string
   data: ISliderCardData[]
}

const SliderBox: React.FunctionComponent<SliderBoxProps> = (props) => {
   return (
      <Box className="pt-1">
         <Box>
            <Text className="text-2xl text-dark-blue font-semibold px-4 py-1">
               {props.title}
            </Text>
            <Slider data={props.data} />
         </Box>
      </Box>
   )
}

export default SliderBox
