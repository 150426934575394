import { Box, Spinner, Text } from '@chakra-ui/react'
import SliderBox from 'Components/Cards/SliderBox'
import RadioInputGroup from 'Components/Inputs/RadioInputGroup'
import FilteredCard from 'Components/Panels/FilteredCard'
import TextSketeton from 'Components/Skeleton/TextSketeton'
import { useFilterByResourseType } from 'Hooks/queries/useFilterByResourseType'
import { useGetAllCourses } from 'Hooks/queries/useGetAllCourses'
import { useGetAllResourseType } from 'Hooks/queries/useGetAllResourseType'
import { useGetAllVideos } from 'Hooks/queries/useGetAllVideos'
import { useGetCategories } from 'Hooks/queries/useGetCategories'
import { useGetEnrollCourses } from 'Hooks/queries/useGetEnrollCourses'
import { useGetFeatureCourses } from 'Hooks/queries/useGetFeatureCourses'
import { useGetFilteredData } from 'Hooks/queries/useGetFilteredData'
import { useGetLessonsPlans } from 'Hooks/queries/useGetLessonsPlans'
import { useSelectBothTypeCategory } from 'Hooks/queries/useSelectBothTypeCategory'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { EnrolledCourese, FilterdData, isEmpty } from 'Recoil/Atoms'
import { isLogin } from 'Utils/HelperMethods'
interface HomePageProps {}

const HomePage: React.FunctionComponent<HomePageProps> = () => {
   const Categories = useGetCategories()
   const CoursesList = useGetAllCourses()
   const ResourseType = useGetAllResourseType()
   const LessonPlans = useGetLessonsPlans()
   const FeatureCourses = useGetFeatureCourses()
   const Videos = useGetAllVideos()
   const [FilteredData] = useRecoilState(FilterdData)
   const [enrolledCourese] = useRecoilState(EnrolledCourese)
   const [isempty] = useRecoilState(isEmpty)
   const SelectCategory = useGetFilteredData()
   const SelectType = useFilterByResourseType()
   const SelectBoth = useSelectBothTypeCategory()

   const { mutate } = useGetEnrollCourses()

   useEffect(() => {
      if (isLogin !== undefined && enrolledCourese.length === 0) {
         mutate()
      }
   }, [])

   const { t } = useTranslation('translation')

   return (
      <>
         <Box className="flex lg:flex-col z-10 w-full">
            <Box className="w-1/4 lg:w-full border-r h-screen lg:h-full bg-white px-12">
               <Box className="py-8">
                  {Categories.isLoading ? (
                     <>
                        <TextSketeton times={4} />
                     </>
                  ) : (
                     <>
                        <Text className="font-medium text-dark-blue text-2xl border-b-2 my-2">
                           {t('Categories')}
                        </Text>
                        <RadioInputGroup
                           type="Categories"
                           data={Categories.Categories}
                           SelectCategory={SelectCategory}
                           SelectType={SelectType}
                           SelectBoth={SelectBoth}
                        />
                     </>
                  )}
               </Box>
               <Box className="py-8">
                  {ResourseType.isLoading ? (
                     <>
                        <TextSketeton times={4} />
                     </>
                  ) : (
                     <>
                        <Text className="font-medium text-dark-blue text-2xl border-b-2 my-2">
                           {t('Resource Type')}
                        </Text>
                        <RadioInputGroup
                           type="Type"
                           SelectCategory={SelectCategory}
                           SelectType={SelectType}
                           SelectBoth={SelectBoth}
                           data={ResourseType.ResourseType}
                        />
                     </>
                  )}
               </Box>
            </Box>
            {FilteredData.length !== 0 ? (
               <>
                  {SelectType.isLoading ||
                  SelectCategory.isLoading ||
                  SelectBoth.isLoading ? (
                     <Box className="pt-16 w-full flex justify-center">
                        <Spinner
                           thickness="4px"
                           speed="0.65s"
                           emptyColor="gray.200"
                           color="primary"
                           size="xl"
                        />
                     </Box>
                  ) : (
                     <Box className="w-3/4 lg:w-full lg:flex-col px-2 h-full flex bg-dark-gray flex-wrap">
                        {FilteredData.map((item) => (
                           <FilteredCard FilterdData={item} />
                        ))}
                     </Box>
                  )}
               </>
            ) : (
               <>
                  {isempty ? (
                     <Box className="w-3/4 lg:w-full px-2 h-full bg-dark-gray">
                        <Text>{t('no_filter_found')}</Text>
                     </Box>
                  ) : (
                     <Box className="w-3/4 lg:w-full px-2 h-full bg-dark-gray">
                        {Categories.isLoading ? (
                           <Box className="pt-16 w-full flex justify-center">
                              <Spinner
                                 thickness="4px"
                                 speed="0.65s"
                                 emptyColor="gray.200"
                                 color="primary"
                                 size="xl"
                              />
                           </Box>
                        ) : (
                           <Box className="pt-8">
                              <SliderBox
                                 title={t('Popular Courses')}
                                 data={CoursesList.removeDraft}
                              />
                              <SliderBox
                                 title={t('Featured Courses')}
                                 data={FeatureCourses.removeDraft}
                              />
                              <SliderBox
                                 title={t('lessons')}
                                 data={LessonPlans.LessonPlans}
                              />
                              <SliderBox
                                 title={t('Videos')}
                                 data={Videos.AllVideos}
                              />
                           </Box>
                        )}
                     </Box>
                  )}
               </>
            )}
         </Box>
      </>
   )
}

export default HomePage
