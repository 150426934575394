import { ListIcon, ListItem } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'

interface IconsListsProps {
   name: string
   data?: number
   icon: IconType
}

const IconsLists: React.FunctionComponent<IconsListsProps> = (props) => {
   const { t } = useTranslation('translation')

   return (
      <ListItem gap="2" display="flex" w="100%" alignItems="center">
         <ListIcon as={props.icon} />
         <span className="font-semibold">{props.data}</span> <span className='w-full'>{t(props.name)}</span> 
      </ListItem>
   )
}

export default IconsLists
