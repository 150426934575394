import React from 'react'
import ReactDOM from 'react-dom/client'
import { i18nextInit } from 'Configs/i18nextInit'
import './index.css'
import App from './App'
import { RecoilRoot } from 'recoil'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClientProvider } from 'react-query'
import { theme } from './Configs/chakraTheme'
import { queryClient } from 'Configs/queryClient'
import { initGA } from 'Utils/Tracking'
import { Suspense } from 'react'

/* Initializing the i18next library. */
i18nextInit()

/* Initializing the Google GA. */
initGA('GTM-KR3Q2BG')

const loadingMarkup = <h1>Loading...</h1>

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
   <BrowserRouter>
      <ChakraProvider theme={theme}>
         <Suspense fallback={loadingMarkup}>
            <RecoilRoot>
               <React.StrictMode>
                  <QueryClientProvider client={queryClient}>
                     <App />
                  </QueryClientProvider>
               </React.StrictMode>
            </RecoilRoot>
         </Suspense>
      </ChakraProvider>
   </BrowserRouter>
)
