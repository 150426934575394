import { useApiRequests } from 'API/apiRequests'
import { useQuery } from 'react-query'
import { useRecoilState } from 'recoil'
import { UserData } from 'Recoil/Atoms'

export const useGetUserData = () => {
   const { getUserData } = useApiRequests()
   const [, setUserData] = useRecoilState(UserData)
   const { data, isLoading } = useQuery(['UserData'], getUserData)
   const userData = data?.data.data
   setUserData(userData)
   return { userData, isLoading }
}
