import { ISliderCardData } from "Models/SliderCard-type"

export interface ICourseData {
   course_id: number
   course : ISliderCardData
}

const useEnrolledCourse = () => {
   const checkEnrollCourse = (id: number, data: ICourseData[]) => {
      const isEnroll = (id: number, data: ICourseData[]) => {
         return data.filter((item) => {
            return item.course_id === id
         })
      }

      return isEnroll(id, data).length === 0 ? false : true
   }

   return { checkEnrollCourse }
}

export default useEnrolledCourse
