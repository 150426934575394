import { useGetAllCourses } from 'Hooks/queries/useGetAllCourses'
import { useGetAllVideos } from 'Hooks/queries/useGetAllVideos'
import { useGetLessonsPlans } from 'Hooks/queries/useGetLessonsPlans'
import { ILessonPlan } from 'Models/LessonPlan-type'
import { ISliderCardData } from 'Models/SliderCard-type'
import { IVideos } from 'Models/Videos-type'
import CourseDetails from 'Pages/CourseDetails/CourseDetails'
import EditProfile from 'Pages/EditProfile/EditProfile'
import ForgetPassword from 'Pages/ForgetPassword/ForgetPassword'
import HomePage from 'Pages/HomePage/HomePage'
import LessonPlanDetails from 'Pages/LessonPlanDetails/LessonPlanDetails'
import Login from 'Pages/Login/Login'
import MyCourses from 'Pages/MyCourses/MyCourses'
import PlayingCourse from 'Pages/PlayingCourse/PlayingCourse'
import Register from 'Pages/Register/Register'
import VideoDetails from 'Pages/VideoDetails/VideoDetails'
import { Route, Routes } from 'react-router-dom'
import NotFound from 'Pages/404/NotFound'
import { Box, Spinner } from '@chakra-ui/react'
interface UserRoutesProps {}

const UserRoutes: React.FunctionComponent<UserRoutesProps> = () => {
   const CoursesList = useGetAllCourses()
   const LessonPlans = useGetLessonsPlans()
   const Videos = useGetAllVideos()
   return (
      <>
         {CoursesList.isLoading || LessonPlans.isLoading || Videos.isLoading ? (
            <Box className="pt-16 w-full flex justify-center">
               <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="primary"
                  size="xl"
               />
            </Box>
         ) : (
            <>
               <Routes>
                  <Route path="/" element={<HomePage />}></Route>
                  <Route
                     path="/resetpassword"
                     element={<ForgetPassword />}></Route>
                  <Route path="/editprofile" element={<EditProfile />}></Route>
                  <Route path="/login" element={<Login />}></Route>
                  <Route path="/my-courses" element={<MyCourses />}></Route>
                  <Route path="/register" element={<Register />}></Route>
                  {CoursesList?.removeDraft?.map((item: ISliderCardData) => (
                     <Route
                        path={`/${item.link}`}
                        element={<CourseDetails data={item} />}></Route>
                  ))}
                  {LessonPlans?.LessonPlans?.map((item: ILessonPlan) => (
                     <Route
                        path={`/${item.link}`}
                        element={<LessonPlanDetails data={item} />}></Route>
                  ))}
                  {Videos?.AllVideos?.map((item: IVideos) => (
                     <Route
                        path={`/${item.link}`}
                        element={<VideoDetails data={item} />}></Route>
                  ))}
                  {CoursesList?.removeDraft?.map((item: ISliderCardData) => (
                     <Route
                        path={`/${item.link}`}
                        element={<CourseDetails data={item} />}></Route>
                  ))}
                  {CoursesList?.removeDraft?.map((item: ISliderCardData) => (
                     <Route
                        path={`/playing/${item.link}`}
                        element={<PlayingCourse data={item} />}></Route>
                  ))}
                  {(!LessonPlans.isLoading ||
                     !CoursesList.isLoading ||
                     !Videos.isLoading) && (
                     <Route path="*" element={<NotFound />} />
                  )}
               </Routes>
            </>
         )}
      </>
   )
}

export default UserRoutes
