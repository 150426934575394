import {
   Box,
   Button,
   Checkbox,
   Input,
   InputGroup,
   Link,
   Text
} from '@chakra-ui/react'
import { ErrorMessage, Form, Formik } from 'formik'
import { registerSchema } from 'Configs/formsSchemas'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRegister } from 'Hooks/queries/useRegister'
interface RegisterFormProps {}

const RegisterForm: React.FunctionComponent<RegisterFormProps> = () => {
   const { t } = useTranslation('translation')
   const { mutate, isLoading } = useRegister()

   return (
      <>
         <Formik
            initialValues={{
               email: '',
               password: '',
               password_confirmation: ''
            }}
            onSubmit={(e) => {
               mutate(e)
            }}
            validationSchema={registerSchema}>
            {({ handleChange, handleSubmit, values }) => (
               <Form className="w-3/5 lg:w-4/5" onSubmit={handleSubmit}>
                  <Box className="py-4">
                     <Input
                        type="email"
                        name="email"
                        className="font-medium py-4"
                        variant="filled"
                        placeholder={t('enter_email')}
                        onChange={handleChange}
                        value={values.email}
                     />
                     <ErrorMessage
                        render={(error) => (
                           <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                              {t(error)}
                           </Box>
                        )}
                        name="email"
                        component="div"
                     />
                  </Box>
                  <Box className="py-4">
                     <InputGroup alignItems="center" size="md">
                        <Input
                           className="font-medium"
                           variant="filled"
                           placeholder={t('enter_password')}
                           name="password"
                           type="password"
                           onChange={handleChange}
                           value={values.password}
                        />
                     </InputGroup>
                     <ErrorMessage
                        name="password"
                        render={(error) => (
                           <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                              {t(error)}
                           </Box>
                        )}
                        component="div"
                     />
                  </Box>
                  <Box className="py-4">
                     <InputGroup alignItems="center" size="md">
                        <Input
                           className="font-medium"
                           variant="filled"
                           placeholder={t('re_enter_password')}
                           name="password_confirmation"
                           onChange={handleChange}
                           type="password"
                           value={values.password_confirmation}
                        />
                     </InputGroup>
                     <ErrorMessage
                        render={(error) => (
                           <Box className="bg-white mt-2 rounded-lg border-2 border-rose-500 text-rose-500 py-2 px-5">
                              {t(error)}
                           </Box>
                        )}
                        name="password"
                        component="div"
                     />
                  </Box>
                  <Box className="flex gap-x-2">
                     <Checkbox required />
                     <Text>
                        {t('Yes-I-agree')}{' '}
                        <Link
                           target="_blank"
                           color="primary"
                           className="text-primary font-semibold pt-4"
                           href="https://thaki.org/privacy-policy/">
                           Privacy Policy
                        </Link>
                     </Text>
                  </Box>
                  <Box className="flex justify-between pt-8">
                     <Button
                        justifyContent="center"
                        variant="primary"
                        isLoading={isLoading}
                        type="submit">
                        {t('register')}
                     </Button>
                  </Box>
               </Form>
            )}
         </Formik>
      </>
   )
}

export default RegisterForm
