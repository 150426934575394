import { useRecoilState } from 'recoil'
import { CurrentEditCourse, CurrentEditLessonPlan } from 'Recoil/Atoms'

const useUpdateEditeCourse = () => {
   const [currentEditable, setCurrentEditable] =
      useRecoilState(CurrentEditCourse)
   const [currentEditableLesson, setCurrentEditableLesson] = useRecoilState(
      CurrentEditLessonPlan
   )
   const [CurrentEditVideo, setCurrentEditVideo] = useRecoilState(
      CurrentEditLessonPlan
   )

   const doUpdateCourse = (el: string, value?: string) => {
      let values = currentEditable
      values = { ...values, [el]: value }
      setCurrentEditable(values)
   }

   const doUpdateLesson = (el: string, value?: string) => {
      let values = currentEditableLesson
      values = { ...values, [el]: value }
      setCurrentEditableLesson(values)
   }

   const doUpdateVideo = (el: string, value?: string) => {
      let values = CurrentEditVideo
      values = { ...values, [el]: value }
      setCurrentEditVideo(values)
   }

   return { doUpdateCourse, doUpdateLesson, doUpdateVideo }
}

export default useUpdateEditeCourse
