import {
   Table,
   Thead,
   Tbody,
   Tr,
   Th,
   Td,
   TableContainer,
   Box,
   Spinner
} from '@chakra-ui/react'
import { useGetAllUsers } from 'Hooks/queries/Admin/useGetAllUsers'

import { IUsers } from 'Models/Users-type'
import { takeLastLtters } from 'Utils/VideoPrfix'

interface UsersTableProps {}

const UsersTable: React.FunctionComponent<UsersTableProps> = () => {
   const UserLists = useGetAllUsers()

   return (
      <>
         <TableContainer>
            <Table>
               <Thead>
                  <Tr>
                     <Th>ID</Th>
                     <Th>Name</Th>
                     <Th>Email</Th>
                     <Th>Role</Th>
                     <Th>Langeuge</Th>
                     <Th>Created At</Th>
                     <Th>Gender</Th>
                     <Th>Country</Th>
                  </Tr>
               </Thead>
               <Tbody>
                  {UserLists.isLoading ? (
                     <Box className="pt-16 w-full flex justify-center">
                        <Spinner
                           thickness="4px"
                           speed="0.65s"
                           emptyColor="gray.200"
                           color="primary"
                           size="xl"
                        />
                     </Box>
                  ) : (
                     <>
                        {UserLists.users.map((item: IUsers) => (
                           <Tr>
                              <Td>{item.id}</Td>
                              <Td>{item.fullName}</Td>
                              <Td>{item.email}</Td>
                              <Td>
                                 {item.role_id === 2 ? (
                                    <>Teacher</>
                                 ) : (
                                    <>Admin</>
                                 )}
                              </Td>
                              <Td>{item.prifLang}</Td>
                              <Td>{takeLastLtters(item.created_at)}</Td>
                              <Td>{item.gender}</Td>
                              <Td>{item.country}</Td>
                           </Tr>
                        ))}
                     </>
                  )}
               </Tbody>
            </Table>
         </TableContainer>
      </>
   )
}

export default UsersTable
