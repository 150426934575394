export const getVideo = (id: string) => {
   return `https://www.youtube.com/embed/${id}`
}

export const getimgThumbnail = (id: string) => {
   return `https://img.youtube.com/vi/${id}/mqdefault.jpg`
}

export const getVideoId = (link: string) => {
   return link.substr(link.length - 11)
}

export const takeLastLtters = (link: string) => {
   return link.substring(0, link.length - 17)
}
