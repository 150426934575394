import { useApiRequests } from 'API/apiRequests'
import { useQuery } from 'react-query'

export const useGetAllUsers = () => {
   const { getAllUsers } = useApiRequests()
   const { data, isLoading } = useQuery(['users'], getAllUsers)
   const users = data?.data.data

   return { users, isLoading }
}
