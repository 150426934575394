import { Box, Text } from '@chakra-ui/react'
import CourseAccordion from 'Components/Accordion/CourseAccordion'
import NextPrev from 'Components/Arrows/NextPrev'
import { useGetCourseLesson } from 'Hooks/queries/useGetCourseLesson'
import { ILesson } from 'Models/Section-type'
import { ISliderCardData } from 'Models/SliderCard-type'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import {
   CurrentCourse,
   CurrentPageIndex,
   CurrentSectionIndex,
   CurrentText
} from 'Recoil/Atoms'
import { currentLang, isLogin } from 'Utils/HelperMethods'

interface PlayingCourseProps {
   data: ISliderCardData
}

const PlayingCourse: React.FunctionComponent<PlayingCourseProps> = (props) => {
   const { mutate } = useGetCourseLesson()
   const [courseLesson] = useRecoilState(CurrentCourse)
   const [courseText, setCourseText] = useRecoilState(CurrentText)
   const [currentPageIndex] = useRecoilState(CurrentPageIndex)
   const [currectSectionIndex] = useRecoilState(CurrentSectionIndex)
   const navigate = useNavigate()
   const { t } = useTranslation('translation')

   useEffect(() => {
      mutate(props.data.id)
      if (isLogin === undefined) {
         navigate('../login')
      }
   }, [])

   useEffect(() => {

      if (courseLesson.length !== 0 && currentLang === 'en') {
         setCourseText({
            text: courseLesson[currectSectionIndex][currentPageIndex].text,
            id: courseLesson[currectSectionIndex][currentPageIndex].id
         })
      }

      if (courseLesson.length !== 0 && currentLang === 'ar') {
         setCourseText({
            text: courseLesson[currectSectionIndex][currentPageIndex].arText,
            id: courseLesson[currectSectionIndex][currentPageIndex].id
         })
      }
   }, [currentPageIndex, currectSectionIndex])

   return (
      <>
         <Box className="flex lg:flex-col">
            <Box className="w-1/3 lg:w-full flex flex-col">
               <Text className="p-8 font-semibold text-xl">
                  {t('Course_Content')} :
                  {currentLang === 'ar' ? (
                     <> {props.data.arTitle}</>
                  ) : (
                     <> {props.data.title}</>
                  )}
               </Text>
               {courseLesson?.map((item: ILesson[], index) => (
                  <CourseAccordion data={item} index={index} />
               ))}
            </Box>
            <Box className="w-2/3 lg:w-full flex flex-col py-24 px-12 bg-dark-gray">
               <Box
                  className="textBox"
                  dangerouslySetInnerHTML={{
                     __html: courseText.text
                  }}></Box>

               <NextPrev
                  currectSectionIndex={currectSectionIndex}
                  currentPageIndex={currentPageIndex}
               />
            </Box>
         </Box>
      </>
   )
}

export default PlayingCourse
