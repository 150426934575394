import { useApiRequests } from 'API/apiRequests'
import { useMutation, useQueryClient } from 'react-query'
import { useRecoilState } from 'recoil'
import { EnrolledCourese } from 'Recoil/Atoms'

export const useGetEnrollCourses = () => {
   const { getEnrolled } = useApiRequests()
   const queryClient = useQueryClient()
   const [, setCourses] = useRecoilState(EnrolledCourese)
   const { isLoading, mutate, ...attributes } = useMutation(getEnrolled, {
      onSuccess: (data) => {
         queryClient.invalidateQueries('enrolled')
         setCourses(data?.data?.data)
      }
   })

   return { isLoading, mutate, ...attributes }
}
