import { ILesson } from 'Models/Section-type'
import { useRecoilState } from 'recoil'
import { CurrentPageIndex, CurrentSectionIndex } from 'Recoil/Atoms'

const useEnrolledCourse = () => {
   const [currentPageIndex, setcurrentPageIndex] =
      useRecoilState(CurrentPageIndex)
   const [currectSectionIndex, setcurrectSectionIndex] =
      useRecoilState(CurrentSectionIndex)

   const handleIncrement = (PagesLength: number) => {
      if (currentPageIndex < PagesLength) {
         setcurrentPageIndex(currentPageIndex + 1)
         setcurrectSectionIndex(currectSectionIndex)
      } else {
         setcurrectSectionIndex(currectSectionIndex + 1)
         setcurrentPageIndex(0)
      }
   }

   const handleDecrement = (PagesLength: number) => {
      if (currentPageIndex > 0) {
         setcurrentPageIndex(currentPageIndex - 1)
         setcurrectSectionIndex(currectSectionIndex)
      } else {
         setcurrectSectionIndex(currectSectionIndex - 1)
         setcurrentPageIndex(PagesLength)
      }
   }

   const handleNextClick = (Sections: ILesson[][]) => {
      const PagesLength = Sections[currectSectionIndex].length - 1
      handleIncrement(PagesLength)
   }

   const handlePrevClick = (Sections: ILesson[][]) => {
      const PagesLength = Sections[currectSectionIndex].length - 1
      handleDecrement(PagesLength)
   }

   return { handleNextClick, handlePrevClick }
}

export default useEnrolledCourse
