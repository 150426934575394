import Cookies from 'js-cookie'

export const currentLang = localStorage.getItem('i18nextLng')
export const isLogin = Cookies.get('token')
export const isAdmin = Cookies.get('role')
document.body.style.overflowX = 'hidden'
document.body.style.overflowY = 'scroll'

export const handleChangeLang = (lang: string) => {
   localStorage.setItem('i18nextLng', lang)
   window.location.reload()
}

export const getCurrentFont = () => {
   if (currentLang === 'en')
      return { body: 'Montserrat', heading: 'Montserrat' }
   if (currentLang === 'ar')
      return { body: 'NotoKufiArabic', heading: 'NotoKufiArabic' }
   if (currentLang === null)
      return { body: 'NotoKufiArabic', heading: 'NotoKufiArabic' }
}
