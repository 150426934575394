import { Box, Spinner, Text } from '@chakra-ui/react'
import SliderCard from 'Components/Cards/SliderCard'
import { useGetEnrollCourses } from 'Hooks/queries/useGetEnrollCourses'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { EnrolledCourese, isLogin } from 'Recoil/Atoms'

interface MyCoursesProps {}

const MyCourses: React.FunctionComponent<MyCoursesProps> = () => {
   const { isLoading, mutate } = useGetEnrollCourses()
   const [enrolledCourese] = useRecoilState(EnrolledCourese)
   const { t } = useTranslation('translation')
   const navigate = useNavigate()
   const [islogin] = useRecoilState(isLogin)

   useEffect(() => {
      if (islogin !== undefined && enrolledCourese.length === 0) {
         mutate()
      }
      if (islogin === undefined) {
         navigate('../login')
      }
   }, [])

   return (
      <>
         <Box className="flex flex-col items-center pt-20">
            {isLoading ? (
               <Box className="pt-16 w-full flex justify-center">
                  <Spinner
                     thickness="4px"
                     speed="0.65s"
                     emptyColor="gray.200"
                     color="primary"
                     size="xl"
                  />
               </Box>
            ) : (
               <>
                  {' '}
                  {enrolledCourese.length === 0 ? (
                     <Text className="text-2xl text-primary">
                        {t('no_course')}...
                     </Text>
                  ) : (
                     <Text className="text-2xl text-primary">
                        {t('Continue_Learning')}...
                     </Text>
                  )}
                  <Box className="flex flex-wrap justify-center items-center gap-6 p-20">
                     {enrolledCourese.map((item) => (
                        <SliderCard fromMyCourse data={item.course} />
                     ))}
                  </Box>
               </>
            )}
         </Box>
      </>
   )
}

export default MyCourses
