import {
   AlertDialog,
   AlertDialogBody,
   AlertDialogContent,
   AlertDialogFooter,
   AlertDialogHeader,
   AlertDialogOverlay,
   Button,
   useDisclosure
} from '@chakra-ui/react'
import { IReactQuery } from 'Models/ReactQuery-type'
import React from 'react'

interface DeleteDialogProps {
   title: string
   id: number
   deleteCourse: IReactQuery
}

const DeleteDialog: React.FunctionComponent<DeleteDialogProps> = (props) => {
   const { isOpen, onOpen, onClose } = useDisclosure()
   const cancelRef = React.useRef<any>()
   const hundleDelete = () => {
      props.deleteCourse.mutate(props.id)
      if (props.deleteCourse.isLoading === false) {
         onClose()
      }
   }

   return (
      <>
         <Button
            onClick={onOpen}
            variant="adminCard"
            color="white"
            bg="red.400">
            Delete
         </Button>

         <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}>
            <AlertDialogOverlay>
               <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                     {props.title}
                  </AlertDialogHeader>
                  <AlertDialogBody>
                     Are you sure? You can't undo this action afterwards.
                  </AlertDialogBody>
                  <AlertDialogFooter>
                     <Button ref={cancelRef} onClick={onClose}>
                        Cancel
                     </Button>
                     <Button
                        colorScheme="red"
                        isLoading={props.deleteCourse.isLoading}
                        onClick={hundleDelete}
                        ml={3}>
                        Delete
                     </Button>
                  </AlertDialogFooter>
               </AlertDialogContent>
            </AlertDialogOverlay>
         </AlertDialog>
      </>
   )
}

export default DeleteDialog
