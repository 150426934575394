import {
   Accordion,
   AccordionButton,
   AccordionIcon,
   AccordionItem,
   AccordionPanel,
   Box,
   Text
} from '@chakra-ui/react'
import { ILesson } from 'Models/Section-type'
import { useRecoilState } from 'recoil'
import {
   CurrentPageIndex,
   CurrentSectionIndex,
   CurrentText
} from 'Recoil/Atoms'
import { currentLang } from 'Utils/HelperMethods'

interface CourseAccordionProps {
   data: ILesson[]
   index: number
}

const CourseAccordion: React.FunctionComponent<CourseAccordionProps> = (
   props
) => {
   const [CourseText, setCourseText] = useRecoilState(CurrentText)
   const [, setcurrentPageIndex] = useRecoilState(CurrentPageIndex)
   const [, setcurrectSectionIndex] = useRecoilState(CurrentSectionIndex)

   const hundleClickLesson = (item: string, id: number, index: number) => {
      setcurrentPageIndex(index)
      setcurrectSectionIndex(props.index)
      setCourseText({
         text: item,
         id: id
      })
   }

   return (
      <Accordion
         key={props.data[0].id}
         allowToggle
         defaultIndex={[0]}
         allowMultiple>
         <AccordionItem>
      
               <AccordionButton
                  bg="dark-gray"
                  borderBottom="1px"
                  borderEnd="1px"
                  className="font-semibold"
                  borderColor="border-gray">
                  {currentLang === 'ar' ? (
                     <Box className="p-2" textAlign="right" flex="1">
                        {props.data[0].section.arTitle}
                     </Box>
                  ) : (
                     <Box className="p-2" textAlign="left" flex="1">
                        {props.data[0].section.title}
                     </Box>
                  )}
                  <AccordionIcon />
               </AccordionButton>
         
            {currentLang === 'ar' ? (
               <>
                  {props.data.map((item, index) => (
                     <AccordionPanel
                        key={item.id}
                        borderBottom="1px"
                        borderEnd="1px"
                        textAlign="right"
                        cursor="pointer"
                        onClick={() => {
                           hundleClickLesson(item.arText, item.id, index)
                        }}
                        borderColor="border-gray"
                        py="8">
                        {CourseText.id === item.id ? (
                           <Text className="font-semibold text-primary">
                              {item.arTitle}
                           </Text>
                        ) : (
                           <Text className="text-light-gray ">
                              {item.arTitle}
                           </Text>
                        )}
                     </AccordionPanel>
                  ))}
               </>
            ) : (
               <>
                  {props.data.map((item, index) => (
                     <AccordionPanel
                        key={item.id}
                        borderBottom="1px"
                        borderEnd="1px"
                        cursor="pointer"
                        onClick={() => {
                           hundleClickLesson(item.text, item.id, index)
                        }}
                        borderColor="border-gray"
                        py="8">
                        {CourseText.id === item.id ? (
                           <Text as="p" className="font-semibold text-primary">
                              {item.title}
                           </Text>
                        ) : (
                           <Text as="p" className="text-light-gray">{item.title}</Text>
                        )}
                     </AccordionPanel>
                  ))}
               </>
            )}
         </AccordionItem>
      </Accordion>
   )
}

export default CourseAccordion
